import React from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useGetUserProfileQuery} from "../services/backendApi";
import {Center, Spinner, useToast} from "@chakra-ui/react";

function PrivateRoute({ children, ...rest }) {
	const userToken = useSelector(state => state.authReducer.userToken)
	const user = useSelector(state => state.authReducer.user)
	const isAuthenticated = userToken && user
	const toast = useToast();

	const { isSuccess, isLoading, isError } = useGetUserProfileQuery(undefined, {
		skip: isAuthenticated, // Skip the query if userToken is not available
	});

	const navigate = useNavigate();

	React.useEffect(() => {
		if (isLoading) {
		//	Should already be loading
		} else if (isSuccess) {
			return (
				<>
					<Outlet />
				</>
			);
		} else if (isError) {
			toast({
				title: `You must be logged in to access the page you were trying to visit.`,
				status: "success",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
			navigate('/');
		} else {
			//	Should already be loading
		}
	}, [isLoading, isSuccess, isError]);

	if (isAuthenticated || isSuccess) {
		return (
			<>
				<Outlet />
			</>
		);
	} else {
		return (<Center>
				<Spinner
					thickness='4px'
					speed='0.65s'
					emptyColor='gray.200'
					color='blue.500'
					size='xl'
				/>
			</Center>
		);
	}

}

export default PrivateRoute;
