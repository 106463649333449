import axios from "axios";

export const getCookie = function (cname) {
	const name = cname + "=";
	const ca = document.cookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
};


export const makeId = function (length) {
	let result = "";
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};


export const transmitMarketingRefSource = async ({uniqueBrowserId, refId,}) => {
	if (uniqueBrowserId && refId) {
		const resp = await axios.post(
			`${process.env.REACT_APP_API_BASE_URL}/api/save_marketing_ref_source`,
			{
				unique_browser_id: uniqueBrowserId,
				ref_id: refId,
			}
		);
		return resp.data;
	}
};


export const setCookie = function (cname, cvalue, exdays = 365, base = "skimwise.com") {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	const expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + base + ";path=/";
};


export const marketingRefSourceInitializer = () => {
	let uniqueBrowserId = getCookie("unique_browser_id");
	const refIds = getCookie("ref_ids");
	if (refIds) {
		if (!uniqueBrowserId) {
			uniqueBrowserId = makeId(12);
			setCookie("unique_browser_id", uniqueBrowserId);
		}
		const transmittedRefIds = getCookie("transmitted_ref_ids");
		const refIdsToBeTransmitted = refIds
			.split(",")
			.filter((x) => !transmittedRefIds?.split(",").includes(x));
		refIdsToBeTransmitted.map((refId) => {
			if (refId) {
				transmitMarketingRefSource({ uniqueBrowserId, refId });
			}
		});

		if (refIdsToBeTransmitted.length > 0) {
			setCookie(
				"transmitted_ref_ids",
				(transmittedRefIds?.split(",") || []).concat(refIdsToBeTransmitted).join(",")
			);
		}
	}
};


// For some reason this service is run twice. Once on load and once on market ref source initializer
export const CookieInitializer = {
	BASE: "skimwise.com",

	setCookie: function (cname, cvalue, exdays = 365) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		let expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=" + this.BASE + ";path=/";
	},

	getCookie: function (cname) {
		let name = cname + "=";
		let ca = document.cookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	},

	fetchRefId: function () {
		return new URL(window.location.href).searchParams.get("ref_id");
	},

	fetchOrCreateAndSetUniqueBrowserId: function () {
		const uniqueBrowserId = this.getCookie("unique_browser_id");
		if (uniqueBrowserId) {
			return uniqueBrowserId;
		} else {
			const id = this.makeId(12);
			this.setCookie("unique_browser_id", id);
			return id;
		}
	},

	makeId: function (length) {
		var result = "";
		var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},

	urlWithoutParams: function () {
		return window.location.protocol + "//" + window.location.host + window.location.pathname;
	},

	run: function () {
		const id = this.fetchOrCreateAndSetUniqueBrowserId();
		const refId = this.fetchRefId();
		if (refId) {
			const oldRefIds = this.getCookie("ref_ids");
			if (oldRefIds) {
				const refIds = [...new Set(oldRefIds.split(",").concat(refId))];
				this.setCookie("ref_ids", refIds.join(","));
			} else {
				this.setCookie("ref_ids", refId);
			}

			const redirectUrl = this.urlWithoutParams();
			marketingRefSourceInitializer();
			if (window.history.replaceState) {
				window.history.replaceState({}, "", redirectUrl);
			} else {
				setTimeout(() => {
					window.location = redirectUrl;
				}, 100);
			}
		}
	},
};