import {
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  List,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import {FiCheck} from "react-icons/fi";
import {Card} from "./card";

export const PricingCard = (props) => {
  const iconColor = useColorModeValue("blue.50", "whiteAlpha.50");
  const { data, button, ...rest } = props;
  const {
    header,
    features,
    price,
    name,
    additionalPlanDescription,
    plan,
    showRate = true,
  } = data;

  return (
    <Card rounded={{ sm: "xl" }} pb={"35px"}{...rest}>
      <VStack spacing={6} mx="6">
        <Heading size={useBreakpointValue({ base: "md", md: "lg" })} fontWeight="extrabold">
          {name}
        </Heading>
        <Text fontSize="22px" textAlign="center"
              // minHeight={"70px"}
        >
          {header}
        </Text>
      </VStack>
      <Flex
        align="flex-end"
        justify="center"
        fontWeight="extrabold"
        color={"accent.100"}
        mt="12"
        mb="8">
        <Heading size={useBreakpointValue({ base: "md", lg: "lg" })} fontWeight="inherit">
          {price}
          {showRate && (
            <Text display="inline" fontSize="lg">
              {" "}
              / month
            </Text>
          )}
        </Heading>
      </Flex>
      <Stack mx="6" spacing="4">
        {button}
        <Text fontSize="md" fontWeight="bold">
          {additionalPlanDescription && additionalPlanDescription}
        </Text>
        <List spacing="4" mx="auto">
          {features.map((feature, index) => (
            <HStack fontWeight="medium" as="li" spacing="3" key={index}>
              <Circle size="6" bg={"gray.50"}>
                <Icon as={FiCheck} color={"accent.100"} />
              </Circle>
              <Text color="muted">{feature}</Text>
            </HStack>
          ))}
        </List>
      </Stack>
    </Card>
  );
};
