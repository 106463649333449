import {
	Box,
	Button,
	Container,
	Divider,
	Heading,
	HStack,
	Skeleton,
	SkeletonText,
	Stack,
	Text,
	useColorModeValue,
	useToast
} from '@chakra-ui/react'
import {useNavigate, useParams} from "react-router-dom";
import {useGetBookQuery, useGetBookSectionQuery, usePostBookFinishedMutation} from "../../../services/backendApi";
import {Pagination} from "./Pagination";
import React, {useEffect, useState} from "react";
import {RadioButton, RadioButtonGroup} from "./RadioButtonGroup";

const SectionContent = ({section, sectionId, ...otherProps}) => {
	const defaultView = localStorage.getItem('readerView') || 'keyIdeas'


	const [contentView, setContentView] = useState(defaultView)

	const changeDefaultContentView = (value) => {
		localStorage.setItem('readerView', value)
		setContentView(value)
	}

	const firstSentence = (paragraph) => {
		const sentences = paragraph.split('. ')
		return sentences[0] + '.';
	}

	const restOfParagraph = (paragraph) => {
		const sentences = paragraph.split('. ')
		return sentences.slice(1).join('. ');
	}

	return (
		<Stack spacing="8">
			<Stack spacing="3">
				<Text color={useColorModeValue('blackAlpha.600', 'whiteAlpha.600')} >
					Section {section.attributes.order}
				</Text>
				<Heading as="h1" size="sm" color={useColorModeValue('gray.700', 'white')}>
					{section.attributes.title}
				</Heading>
			</Stack>


			<Box bg="bg.surface"
			     py={{
				     base: '2',
				     md: '4',
			     }}
			>
				<HStack>
					<Divider />
					<RadioButtonGroup value={contentView} onChange={changeDefaultContentView}>
						<RadioButton key={'keyIdeas'} value="keyIdeas">Key Insights ({section.attributes.highlights?.length || 0})</RadioButton>
						<RadioButton key={'summary'} value="summary">Summary</RadioButton>
					</RadioButtonGroup>
					<Divider />
				</HStack>
			</Box>

			<Stack
				spacing="5"
				lineHeight="1.75"
				maxW="3xl"
				color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
			>
				{section.attributes.highlights && section.attributes.highlights.length > 0 &&
					contentView === 'keyIdeas' &&
					section.attributes.highlights.map((highlight, idx) => (
						<React.Fragment
							key={idx}
						>
							<Text
								as={"b"}
								whiteSpace="pre-wrap"
								fontSize={"22px"}
								textStyle={"reader"}
								lineHeight={"35px"}
							>
								{firstSentence(highlight)}
							</Text>
							<Text
								whiteSpace="pre-wrap"
								fontSize={"22px"}
								textStyle={"reader"}
								lineHeight={"35px"}
							>
								{restOfParagraph(highlight)}
							</Text>

						</React.Fragment>

					))
				}

				{contentView === 'summary' && <SummaryText content={section.attributes.content} />}

			</Stack>
		</Stack>
	)
}

const SummaryText = ({content}) => {
	if (Array.isArray(content)) {
		return (
			<>
				{
					content.map((paragraph, idx) => (
						<React.Fragment key={`${paragraph.title}_${idx}`}>
							<Text whiteSpace="pre-wrap"
							      as={"b"}
							      fontSize={"22px"}
							      textStyle={"reader"}
							      lineHeight={"35px"}
							>
								{paragraph.title}
							</Text>

							<Text whiteSpace="pre-wrap"
							      fontSize={"22px"}
							      textStyle={"reader"}
							      lineHeight={"35px"}
							      pt={"5px"}
							      pb={"5px"}
							>
								{paragraph.text}
							</Text>

						</React.Fragment>
					))

				}
			</>
		)
	} else {
		return (
			<Text whiteSpace="pre-wrap"
			      fontSize={"22px"}
			      textStyle={"reader"}
			      lineHeight={"35px"}
			>
				{content}
			</Text>
		)
	}
}

const LoadingSection = () =>  {
	return(
		<Stack spacing="8">
			<Stack spacing="3">
				<Skeleton height="40px" />
			</Stack>
			<Stack
				spacing="5"
				lineHeight="1.75"
				maxW="3xl"
				color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
			>
				<SkeletonText mt='4' noOfLines={30} spacing='4' skeletonHeight='2' />
			</Stack>
		</Stack>
	)
}

export const SectionPagination = ({section, bookId, ...otherProps}) => {
	const { data: bookData, isLoading: bookIsLoading, isError: bookError } = useGetBookQuery(bookId)
	const book = bookData?.data;

	const navigate = useNavigate();


	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
				const currentPage = getCurrentPage(section, book);
				let newPage = currentPage;
				if (event.key === 'ArrowLeft') {
					newPage = currentPage - 1;
				} else if (event.key === 'ArrowRight') {
					newPage = currentPage + 1;
				}
				if (newPage >= 1 && newPage <= book.attributes.toc.length) {
					changePage(newPage);
				}
			}
		};

		document.addEventListener('keydown', handleKeyPress);

		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, []);

	if (bookIsLoading || bookError) {
		return (
			<>
			</>
		)
	}



	const changePage = (pageNumber) => {
		const newSectionId = getSectionId(pageNumber, book);
		const url = `/reader/${bookId}/sections/${newSectionId}`
		// We just navigate to this page using react router
		navigate(url)
	}

	return(
		<Container
			py={{
				base: '12',
				md: '16',
			}}
			maxW="3xl"
			key={section.id}
		>
			{book &&
				<Pagination
					count={book.attributes.toc.length}
					pageSize={1}
					siblingCount={book.attributes.toc.length}
					page={getCurrentPage(section, book)}
					onChange={(pageObject) => changePage(pageObject.page)}
				/>
			}
		</Container>
	)
}

const getCurrentPage = (section, book) => {
	let page = null;
	book.attributes.toc.forEach((s, i) => {
		if(s.id === section.id) {
			page = i;
		}
	})
	return page + 1;
}

const getSectionId = (pageNumber, book) => {
	let sectionId = null;
	book.attributes.toc.forEach((s, i) => {
		if(i === pageNumber - 1) {
			sectionId = s.id;
		}
	})
	return sectionId;
}

const MarkBookFinished = ({bookId}) => {
	const toast = useToast();
	const [markBookFinished, { isLoading, isError }] = usePostBookFinishedMutation();

	const navigate = useNavigate();

	const handleButtonClick = async () => {
		try {
			const response = await markBookFinished(bookId);

			if (response.data && response.data.data.attributes) {
				navigate(`/books/${bookId}`);
				toast({
					title: `"${response.data.data.attributes.title}" has been marked as finished.`,
					status: "success",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: "Whoops something went wrong.",
					status: "error",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (error) {
			toast({
				title: "Whoops something went wrong.",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<Box as="main"
		     maxW="3xl"
		     mx="auto"
		     py="8"
		     px={{
			     base: '4',
			     md: '8',
		     }}
		     display="flex"
		     justifyContent="center"
		     alignItems="center"
		>
			<Box textAlign="center">
				<Heading size="xs">
					You've reached the last section
				</Heading>
				<Button
					variant="primary"
					alignSelf="start"
					onClick={handleButtonClick}
					disabled={isLoading}
				>
					Mark Book Finished
				</Button>

			</Box>
		</Box>

	);
}

export const Section = (props) => {
	const { bookId, sectionId } = useParams();

	const { data: sectionData, isLoading, isError } = useGetBookSectionQuery({sectionId: sectionId, bookId: bookId})
	const section = sectionData?.data;

	return (
		<>
			<Box as="main"
			     maxW="3xl"
			     mx="auto"
			     py="8"
			     px={{
				     base: '4',
				     md: '8',
			     }}
			>
				{isLoading && <LoadingSection />}
				{section && <SectionContent section={section} sectionId={sectionId} />}
			</Box>
			{section && <SectionPagination section={section} bookId={bookId} />}
			{section && section.attributes.last_section && <MarkBookFinished bookId={bookId} />}
		</>

	)
}