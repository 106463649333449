import {AspectRatio, Box, Image, Skeleton} from "@chakra-ui/react";
import React from "react";


export const BookCoverImage = ({ book, ...rootProps }) => {
	return (
		<Box  position="relative" overflow="hidden"
		      boxShadow='xl'
		      _hover={{
			      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
			      transform: 'translateY(-4px) scale(1.05)',
			      transition: 'transform 0.3s ease',
		      }}
		      mb={"10px"}
		      {...rootProps}
		>

			<AspectRatio ratio={1 / 1.5}>
				<Image
					src={`${book.attributes.cover_photo_url.replace('.png', '_400.png')}`}
					fallbackSrc={book.attributes.cover_photo_url}
					alt={book.attributes.title} fallback={<Skeleton />} />
			</AspectRatio>
			<Box
				position="absolute"
				inset="0"
				bgGradient="linear(
	    90deg,
	    rgba(0, 0, 0, 0.118) 1.75%,
	    rgba(255, 255, 255, 0.2) 2.63%,
	    rgba(255, 255, 255, 0.1) 3.48%,
	    rgba(0, 0, 0, 0.05) 4.36%,
	    rgba(255, 255, 255, 0.14) 6.78%,
	    rgba(244, 244, 244, 0) 8.06%
	  );"
				boxSize="full"
			/>

		</Box>
	)
}
