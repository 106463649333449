import {theme as proTheme} from '@chakra-ui/pro-theme'
import {extendTheme, theme as baseTheme} from '@chakra-ui/react'
import '@fontsource/libre-baskerville'
import '@fontsource-variable/libre-franklin'
import '@fontsource/source-serif-pro';

export const theme = extendTheme(
	proTheme,
	{
		colors: { ...baseTheme.colors,
			brand: {
				50: "#EB7F00",
				100: "#EB7F00",
				200: "#EB7F00",
				300: "#EB7F00",
				400: "#EB7F00",
				500: "#EB7F00",
				600: "#EB7F00",
				700: "#EB7F00",
				800: "#EB7F00",
				900: "#EB7F00"
			},
			lightAccent: {
				50: "#F1E9DE",
				100: "#F1E9DE",
				200: "#F1E9DE",
				300: "#F1E9DE",
				400: "#F1E9DE",
				500: "#F1E9DE",
				600: "#F1E9DE",
				700: "#F1E9DE",
				800: "#F1E9DE",
				900: "#F1E9DE"
			},
			accent: {
				50: "#F9723B",
				100: "#F9723B",
				200: "#F9723B",
				300: "#F9723B",
				400: "#F9723B",
				500: "#F9723B",
				600: "#F9723B",
				700: "#F9723B",
				800: "#F9723B",
				900: "#F9723B"
			}
		},
		fonts: {
			heading: "'Source Serif Pro', serif;",
			body: "'Libre Franklin Variable', sans-serif;",
			reader: "'Source Serif Pro', serif;",
		},
		textStyles: {
			reader: {
				fontFamily: "reader",
			},
		},
	},

)