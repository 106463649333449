import {Box, Container, Heading, Stack, Text} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import React from "react";
import {BooksGrid} from "../components/BooksGrid";

const MostPopular = () => {
	return (
		<Box bg="bg.surface">
			<Container py={{ base: '12' }}>
				<Stack spacing={{ base: '12', md: '16' }}>
					<Stack direction="row" justify="space-between">
						<Stack spacing={{ base: '4', md: '5' }}>
							<Stack spacing="3">
								<Link to={`/explore`}>
									<Text color="accent" fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
										Explore
									</Text>
								</Link>
								<Heading size={{ base: 'sm', md: 'md' }}>
									Most Popular
								</Heading>
							</Stack>
							<Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
								Books that are highly read and rated by online reading communities.
							</Text>
						</Stack>
					</Stack>
					<BooksGrid filter={'most_popular'} />
				</Stack>
			</Container>
		</Box>
	);
}

export default MostPopular