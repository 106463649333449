import {useGoogleLogin} from "@react-oauth/google";
import {
	Button,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useDisclosure
} from "@chakra-ui/react";
import axios from "axios";
import {GoogleIcon} from "./ProviderIcons";
import {getCookie} from "../services/marketingRefSourceInitializer";

function GoogleConnectModal(props) {
	const {title, buttonText} = props

	const OverlayOne = () => (
		<ModalOverlay
			bg='blackAlpha.300'
			backdropFilter='blur(30px)'
		/>
	)

	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Button
				onClick={() => {
					onOpen()
				}}
			>
				{buttonText ? buttonText : 'Start Free Trial'}
			</Button>
			<Modal isCentered isOpen={isOpen} onClose={onClose}>
				<OverlayOne />
				<ModalContent>
					<ModalHeader>
						{title ? title : "First time here?"}
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>This app needs to connect to your Google account to send emails on your behalf and manage your emails to provide a stellar experience</Text>
						<Text mt={"20px"}>
							Learn about <Link color='teal.500' href={"/privacy-policy"} isExternal>how we manage privacy</Link> and <Link color='teal.500' href={"/data-disclosure"} isExternal> how we use your data</Link> here.
						</Text>
					</ModalBody>
					<ModalFooter>
						<Button mr={"10px"} onClick={onClose}>Close</Button>
						<LoginWithGoogleButton />
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}

const LoginWithGoogle = (props) => {
	const {title, buttonText} = props
	return (
		<>
			<LoginWithGoogleButton />
			{/*<GoogleConnectModal title={title} buttonText={buttonText} />*/}
		</>
	)
}



const LoginWithGoogleButton = ({ buttonType, ...otherProps }) => {
	const redirectURI = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_GOOGLE_REDIRECT_URI}`
	const uniqueBrowserId = getCookie("unique_browser_id");
	const login = useGoogleLogin({
		flow: 'auth-code',
		scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
		redirect_uri: redirectURI,
		access_type: 'offline',
		state: uniqueBrowserId,
		ux_mode: 'redirect',
		onSuccess: async (response) => {
			const headers = {}
			response['redirect_uri'] = redirectURI
			const callBackUrl = redirectURI
			await axios.post(callBackUrl, response, {headers: headers});
		},
		onError: errorResponse => console.log(errorResponse),
	});

	if (buttonType === 'shortened') {
		return (
			<Button variant="secondary" onClick={() => login()} leftIcon={<GoogleIcon />} iconSpacing="3">
				Sign In
			</Button>
		);
	} else {
		return (
			<Button variant="secondary" onClick={() => login()} leftIcon={<GoogleIcon boxSize="5" />} iconSpacing="3" color={"#75758B"}>
				Sign in with Google
			</Button>
		);
	}

};

export default LoginWithGoogle;
