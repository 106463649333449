import {Box, Flex, HStack, Skeleton, SkeletonText, Stack, Text, useToast,} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'

import {Link, Outlet, useNavigate, useParams} from 'react-router-dom';
import {useGetBookQuery} from "../../../services/backendApi";

import {FiMenu} from 'react-icons/fi'
import {ColumnHeader, ColumnHeading, ColumnIconButton} from './Column'
import {Navbar} from './Navigation'
import {useSelector} from "react-redux";
import {getAccount} from "../../auth/authReducer";

const ReaderViewLoading = () => {
	const [showNav, setShowNav] = useState(false)
	const [mainIsScrolled, setMainIsScrolled] = useState(false)
	return (
		<Flex height="100vh">
			<Box
				height="full"
				width={{
					md: '14rem',
					xl: '18rem',
				}}
				display={{
					base: showNav ? 'initial' : 'none',
					lg: 'initial',
				}}
				overflowY="auto"
				borderRightWidth="1px"
				padding={"10px"}
				py={"50px"}
			>
				<Stack px="3" spacing="6">
					<Stack spacing="1">
						{Array.from({ length: 15 }).map((_, index) => (
							<Skeleton key={`loading_${index}`} height="40px" />
						))}
					</Stack>
				</Stack>

			</Box>
			<Box
				bg={'white'}
				flex="1"
				overflowY="auto"
				onScroll={(x) => setMainIsScrolled(x.currentTarget.scrollTop > 32)}
				padding={"50px"}
			>
				<Skeleton height="50px" />
				<SkeletonText mt='30px' noOfLines={30} spacing='4' skeletonHeight='2' />
			</Box>
		</Flex>
	)
}


function ReaderView() {
	const { bookId, sectionId } = useParams();
	const { data: bookData, isLoading, isError } = useGetBookQuery(bookId)
	const book = bookData?.data;

	const [mainIsScrolled, setMainIsScrolled] = useState(false)
	const [showNav, setShowNav] = useState(false)

	const account = useSelector(getAccount)
	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		if (account && account?.attributes?.status !== 'active') {
			if (book && !book.attributes?.free_to_read) {
				navigate(`/books/${book.id}`);
				// Rendering twice. This makes it so only one shows.
				toast.closeAll()
				toast({
					title: `You must have an active subscription to read this book`,
					status: "warning",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		}
	}, [account, book]);

	if (isLoading || isError) {
		return(
			<ReaderViewLoading />
		)
	}


	return(
		<Flex height="100vh">
			<Box
				height="full"
				width={{
					md: '14rem',
					xl: '18rem',
				}}
				display={{
					base: showNav ? 'initial' : 'none',
					lg: 'initial',
				}}
				overflowY="auto"
				borderRightWidth="1px"
			>
				<Navbar book={book} isLoading={isLoading} isError={isError} showNav={showNav} setShowNav={setShowNav} />
			</Box>
			<Box
				bg={'white'}
				flex="1"
				overflowY="auto"
				onScroll={(x) => setMainIsScrolled(x.currentTarget.scrollTop > 32)}
			>

				{book &&
					<ColumnHeader shadow={mainIsScrolled ? 'base' : 'none'}>
						<HStack justify="space-between" width="full">
							<HStack spacing="3">
								<ColumnIconButton
									aria-label="Open Navigation"
									icon={<FiMenu/>}
									display={{
										base: 'inline-flex',
										md: 'none',
									}}
									onClick={() => setShowNav(!showNav)}
								/>
								<Link to={`/books/${book.id}`}>
									<HStack  >
										<ColumnHeading pl={"10px"}>{book.attributes.title}</ColumnHeading>
										<Text
											fontSize={"md"}
											noOfLines={1}
											css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
										>
											by {book.attributes.authors}
										</Text>
									</HStack>
								</Link>
							</HStack>
						</HStack>
					</ColumnHeader> }
				{book &&
					<Outlet key={sectionId} book={book}  />
				}
			</Box>
		</Flex>
	)

}

export default ReaderView
