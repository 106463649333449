import {Box, Container, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {Logo} from "../../../components/Logo";
import LoginWithGoogle from "../../../components/LoginWithGoogle";

export const SignupPage = () => (
	<Box bgGradient={'linear(to-br, lightAccent.300, brand.100)'} py={{ base: '12', md: '24' }} minHeight="100vh" >
		<Container
			maxW="md"
			py={{ base: '0', sm: '8' }}
			px={{ base: '4', sm: '10' }}
			bg={{ base: 'transparent', sm: 'bg-surface' }}
			boxShadow={{ base: 'none', sm: 'xl' }}
			borderRadius={{ base: 'none', sm: 'xl' }}
		>
			<Stack spacing="8">
				<Stack spacing="6" align="center">
					<Link href={"/"}>
						<Logo />
					</Link>
					<Stack spacing="3" textAlign="center">
						<Heading size="xs">Begin reading on Skimwise</Heading>
						<Text fontSize={"20px"}>Create an account to get access to over <Link target="_blank" href={"/explore/free-to-read"} color={"brand.300"}>10 free book summaries!</Link>
						</Text>
					</Stack>
				</Stack>
				<Stack spacing="6">
					<LoginWithGoogle />
				</Stack>
				<Text fontSize="xs" color="subtle" textAlign="center" mt={"20px"}>
					By signing up you are agreeing to <Link color='teal.500' href={"https://skimwise.webflow.io/privacy-policy"} isExternal>how we manage privacy</Link> and <Link color='teal.500' href={"https://skimwise.webflow.io/terms-of-service"} isExternal> our terms of service</Link> here.
				</Text>

				<Text fontSize={"sm"}  textAlign="center" mt={"20px"}>
					Already have an account? <Link href="/login" color={"brand.300"}>Log in</Link>
				</Text>
			</Stack>
		</Container>
	</Box>
)