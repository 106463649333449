import Home from "./home";
import Queue from "./queue";
import History from "./history";
import Finished from "./finished";

const libraryRoutesList = [
	{
		path: "",
		element: <Home />,
		title: "My Library"
	},
	{
		path: "finished",
		element: <Finished />,
		title: "Finished"
	},
	{
		path: "queue",
		element: <Queue />,
		title: "Queue"
	},
	{
		path: "history",
		element: <History />,
		title: "History"
	}

];

export default libraryRoutesList;