import {Button, useToast} from "@chakra-ui/react";
import {usePostBillingPortalUrlMutation, usePostCheckoutUrlMutation} from "../services/backendApi";


export const ManageBillingButton = ({}) => {
	const toast = useToast();

	const [postBillingPortalUrl, { isLoading, isError }] = usePostBillingPortalUrlMutation();

	const handleButtonClick = async () => {
		try {
			const response = await postBillingPortalUrl();

			if (response.data && response.data.redirect_url) {
				window.location.replace(response.data.redirect_url);
			} else {
				toast({
					title: "Whoops something went wrong.",
					status: "error",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (error) {
			toast({
				title: "Whoops something went wrong.",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<Button
			variant="primary"
			alignSelf="start"
			onClick={handleButtonClick}
			disabled={isLoading}
		>
			Manage Billing Profile
		</Button>
	);
};


export const CheckoutButton = ({ planRate, language }) => {
	const toast = useToast();

	const [postCheckoutUrl, { isLoading, isError }] = usePostCheckoutUrlMutation();

	const handleButtonClick = async () => {
		try {
			const response = await postCheckoutUrl(planRate);

			if (response.data && response.data.checkout_url) {
				window.location.replace(response.data.checkout_url);
			} else {
				toast({
					title: "Whoops something went wrong.",
					status: "error",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (error) {
			toast({
				title: "Whoops something went wrong.",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
		}
	};

	return (
		<Button
			variant="primary"
			size="lg"
			onClick={handleButtonClick}
			disabled={isLoading}
		>
			{language}
		</Button>
	);
};