import React, {useCallback, useEffect, useState} from 'react';
import {
	Box,
	HStack,
	Icon,
	Input,
	Link,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	Text,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import {useGetBooksQuery} from "../../services/backendApi";
import {BookRow} from "../BookRow";
import {debounce} from 'lodash';
import {FaArrowRight} from "react-icons/fa";

export const SearchBox = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [searchValue, setSearchValue] = useState('');
	const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

	const { data: bookData, isLoading, isFetching, isError } = useGetBooksQuery({filter: '', search: debouncedSearchValue, page: 1},
		{ skip: !debouncedSearchValue }
	);
	const books = bookData?.data;

	// Debounce search input
	const debouncedSave = useCallback(
		debounce(nextValue => setDebouncedSearchValue(nextValue), 750),
		[], // will be created only once initially
	);

	const handleSearchChange = (event) => {
		const nextValue = event.target.value;
		setSearchValue(nextValue);
		debouncedSave(nextValue); // Call debounced function
	};

	useEffect(() => {
		// Clean up function
		return () => {
			debouncedSave.cancel();
		};
	}, [debouncedSave]);


	const clearValueAndClose=() => {
		setSearchValue('');
		onClose();
	}

	const handleKeyDown = (event) => {
		// if (event.metaKey && event.key === 'k') {
		// 	event.preventDefault();
		// }
		onOpen();
	};

	const Loading = () => {
		return(
			<Box>
				<Text>Loading...</Text>
			</Box>
		)
	}

	const Books = ({books}) => {
		if (books.length > 0) {
			const resultsToDisplay = 4;
			return(
				<Box>
					<VStack spacing={"5"} align={"start"}>
						{books && books.length > 0 && books.slice(0, resultsToDisplay).map((book) => {
								return (
									<BookRow key={book.id} book={book} otherFunction={clearValueAndClose} />
								)
							})
						}
					</VStack>
					{ (books.length > resultsToDisplay) &&
						<Link href={`/explore/search/${searchValue}`}
						      fontWeight="semibold" color="brand.300"
						>
							<HStack pt={"15px"}>
								<Text>See all results</Text>
								<Icon
									as={FaArrowRight}
									color="brand.300"
									fontSize={{
										base: 'sm',
										md: 'md',
									}}
								/>
							</HStack>

						</Link>
					}
				</Box>
			)
		} else {
			if (searchValue) {
				return(
					<Box>
						<Text>No books found for "{searchValue}"</Text>
					</Box>
				)
			} else {
				return(
					<>
					</>
				)
			}
		}
	}

	return (
		<Box onKeyDown={handleKeyDown}>
			<Input placeholder="Search..." onChange={handleSearchChange} />

			<Modal isOpen={isOpen} onClose={clearValueAndClose} size={"xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalBody>
						<Box py={"10px"}>
							<Input placeholder="Search..." value={searchValue} onChange={handleSearchChange} />

							<Box py={"10px"} mt={"20px"}>
								{ (isLoading || isFetching) && Loading()}
								{!isLoading && !isFetching && books && <Books books={books}/>}
							</Box>
						</Box>


					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}
