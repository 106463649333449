import {Button, Link, useToast} from "@chakra-ui/react";
import {usePostBookQueuedMutation, usePostBookUnqueuedMutation} from "../services/backendApi";
import {useNavigate} from "react-router-dom";
import {FiMinusSquare, FiPlusSquare} from "react-icons/fi";


export const MarkAsQueuedButton = ({ bookId, children, ...rootProps }) => {
	const toast = useToast();
	const navigate = useNavigate();
	const [markBookQueued, { isLoading, isError }] = usePostBookQueuedMutation();

	const handleButtonClick = async () => {
		try {
			const response = await markBookQueued(bookId);

			if (response.data && response.data.data.attributes) {
				navigate(`/books/${bookId}`);
				toast({
					title: `"${response.data.data.attributes.title}" has been added to your books to read later.`,
					status: "success",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: "Whoops something went wrong.",
					status: "error",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (error) {
			toast({
				title: "Whoops something went wrong.",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
		}
	};

	if (children) {
		return (
			<Link onClick={handleButtonClick}>
				{children}
			</Link>
		)
	} else {
		return (
			<Button variant="secondary" onClick={handleButtonClick} leftIcon={<FiPlusSquare />}
			        {...rootProps}
			        iconSpacing="3">
				Add to Reading List
			</Button>
		)
	}
}


export const MarkAsUnqueuedButton = ({ bookId, children, ...rootProps }) => {
	const toast = useToast();
	const navigate = useNavigate();
	const [markBookUnqueued, { isLoading, isError }] = usePostBookUnqueuedMutation();

	const handleButtonClick = async () => {
		try {
			const response = await markBookUnqueued(bookId);

			if (response.data && response.data.data.attributes) {
				navigate(`/books/${bookId}`);
				toast({
					title: `"${response.data.data.attributes.title}" has been removed from your books to read later.`,
					status: "success",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			} else {
				toast({
					title: "Whoops something went wrong.",
					status: "error",
					duration: 9000,
					isClosable: true,
					position: "top",
				});
			}
		} catch (error) {
			toast({
				title: "Whoops something went wrong.",
				status: "error",
				duration: 9000,
				isClosable: true,
				position: "top",
			});
		}
	};

	if (children) {
		return (
			<Link onClick={handleButtonClick}>
				{children}
			</Link>
		)
	} else {
		return (
			<Button onClick={handleButtonClick} leftIcon={<FiMinusSquare />}
			        {...rootProps}
			        iconSpacing="3">
				Remove from Reading List
			</Button>
		)
	}
}