import {Box, Heading, HStack, Spinner, Stack, Text, useBreakpointValue,} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {grabFirstName} from "../../../utils/grab-first-name";
import {PricingCard} from "./pricing-card";
import {useGetUserProfileQuery} from "../../../services/backendApi";
import {CheckoutButton} from "../../../components/StripeButtons";
import {PlanSelectionFooter} from "./footer";
import {useSelector} from "react-redux";
import {getAccount} from "../../auth/authReducer";
import {useNavigate} from "react-router-dom";


export const PlanSelection = () => {
  const plans = ["month", "year"]
  const { data: userData, isSuccess, isLoading: loading } = useGetUserProfileQuery(undefined, {});
  const user = userData?.data;
  const [planRate, setPlanRate] = useState("month");
  const breakPointValue = useBreakpointValue({ base: "sm", md: "md" });

  const account = useSelector(getAccount)
  const navigate = useNavigate();

  useEffect(() => {
    if (user && account && account?.attributes?.status === 'active') {
      navigate(`/explore`);
    }
  }, [account, user]);

  if (loading) {
    return <Spinner />;
  }


  return (
    <Stack
      as="section"
      py="14"
      px={{ base: "4", md: "8" }}
      alignItems="center"
      justifyContent="center"
      mx="auto"
      spacing={8}>
      <HStack alignItems={"space-between"} mx="auto" width="full">
        <Box mx="auto">
          <Heading size={breakPointValue} textAlign="center">Hi {grabFirstName(user.attributes.name)},</Heading>
          <Text fontSize={'lg'} textAlign="center" mt={"10px"}>
            You're just a few moments away from accessing 2k+ books on Skimwise. Select a plan to get
            started:
          </Text>
        </Box>
      </HStack>
      <Stack direction={{ base: "column", lg: "row" }} spacing={8}>
        <PricingCard
          zIndex={1}
          data={{
            header: "Pay as you go, cancel anytime.",
            price: "$4.99",
            plan: 'month',
            name: "Monthly",
            additionalPlanDescription: "Key features:",
            features: [
              "Unlimited access to Skimwise’s entire library"
            ],
          }}
          button={<CheckoutButton planRate={'month'} language={'Start Reading Now'}/>}
        />
        <PricingCard
          zIndex={1}
          isPopular
          data={{
            header: "Save 50% with $29.99/year",
            price: "$2.49",
            plan: 'year',
            name: "Yearly",
            additionalPlanDescription: "Key features:",
            features: [
              "Unlimited access to Skimwise’s entire library",
              "Request new titles for our library",
              "Lock-in the current promotional price"
            ],
          }}
          button={<CheckoutButton planRate={'year'} language={'Save 50% now'}/>}
        />
      </Stack>
      <PlanSelectionFooter />
    </Stack>
  );
};
