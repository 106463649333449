import {
	Box,
	Button,
	Flex,
	HStack,
	Icon,
	Link as ChakraLink,
	Skeleton,
	Stack,
	Text,
	useColorModeValue as mode,
} from '@chakra-ui/react'
import {FiArrowUpRight, FiX,} from 'react-icons/fi'
import {ColumnHeader, ColumnIconButton} from './Column'
import {Logo} from "../../../components/Logo";
import React from "react";
import {NavLink as RouterNavLink, useParams} from 'react-router-dom';
import {CardWithAvatar} from "./BookCard";

export const Navbar = (props) => {
	const {book, showNav, setShowNav, isLoading, isError, ...otherProps} = props
	const {bookId, sectionId} = useParams()

	return (
		<Flex as="nav" height="full" direction="column" justify="space-between" {...otherProps}>
			<Stack spacing="3">
				<ColumnHeader>
					<HStack spacing="3">
						<ColumnIconButton
							onClick={() => setShowNav(!showNav)}
							aria-label="Close navigation"
							icon={<FiX/>}
							display={{
								base: 'inline-flex',
								lg: 'none',
							}}
						/>
						<ChakraLink href={"/"}>
							<Logo/>
						</ChakraLink>
					</HStack>
				</ColumnHeader>

				{book &&

					<Box
						px={{
							base: '1',
							md: '2',
						}}
						pt="1"
						pb={"3"}
						width={"full"}
					>
						<CardWithAvatar
							key={book.id}
							book={book}
						>
						</CardWithAvatar>
					</Box>

				}



				{isLoading &&
					<Stack px="3" spacing="6">
						<Stack spacing="1">
							{Array.from({ length: 15 }).map((_, index) => (
								<Skeleton key={index} height="40px" />
							))}
						</Stack>
					</Stack>
				}

				{book &&
					<Stack px="3" spacing="6" pb={"30px"}>
						<Stack spacing="1">
							{book.attributes.toc.map((section) => {
								return (
									<SectionLink key={section.id} number={section.number} to={`/reader/${bookId}/sections/${section.id}`} setShowNav={setShowNav}>
										{section.title}
									</SectionLink>
								)
							})
							}
						</Stack>
					</Stack>
				}


			</Stack>
		</Flex>
	)
}

const NavButton = (props) => (
	<Button
		width="full"
		borderRadius="0"
		variant="tertiary"
		size="xl"
		fontSize="sm"
		_hover={{
			bg: mode('gray.100', 'gray.700'),
		}}
		_active={{
			bg: mode('gray.200', 'gray.600'),
		}}
		_focus={{
			boxShadow: 'none',
		}}
		_focusVisible={{
			boxShadow: 'outline',
		}}
		{...props}
	/>
)

export const SectionLink = (props) => {
	const { icon, setShowNav, ...linkProps } = props
	return (
		<ChakraLink
			as={RouterNavLink}
			px="2"
			py="1.5"
			borderRadius="md"
			_hover={{
				bg: mode('gray.100', 'gray.700'),
			}}
			_activeLink={{
				bg: 'brand.300',
				color: 'white',
			}}
			onClick={() => setShowNav(false) }
			{...linkProps}
		>
			<HStack justify="space-between">
				<HStack spacing="1">
					{/*<Icon as={icon} />*/}
					<Box width="39px" textAlign={"center"} pr={"8px"}>
						<Text fontSize={"23px"}>{props.number}</Text>
					</Box>
					<Box flex={"1"}>
						<Text fontSize="sm"
						      noOfLines={2} css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
						>
							{props.children}
						</Text>
					</Box>
				</HStack>
				{props.isExternal && (
					<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
				)}
			</HStack>
		</ChakraLink>
	)
}

export const NavLink = (props) => {
	const { icon, ...linkProps } = props
	return (
		<ChakraLink
			px="2"
			py="1.5"
			borderRadius="md"
			_hover={{
				bg: mode('gray.100', 'gray.700'),
			}}
			_activeLink={{
				bg: 'gray.700',
				color: 'white',
			}}
			{...linkProps}
		>
			<HStack justify="space-between">
				<HStack spacing="1">
					{/*<Icon as={icon} />*/}
					<Box width="30px">
						<Text fontSize={"23px"}>{props.number}</Text>
					</Box>
					<Box flex={"1"}>
						<Text fontSize="xs"
						      noOfLines={2} css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
						>
							{props.children}
						</Text>
					</Box>
				</HStack>
				{props.isExternal && (
					<Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
				)}
			</HStack>
		</ChakraLink>
	)
}


export const NavHeading = (props) => (
	<Text
		as="h4"
		fontSize="xs"
		fontWeight="semibold"
		px="2"
		lineHeight="1.25"
		color={mode('gray.600', 'gray.400')}
		{...props}
	/>
)