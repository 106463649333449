import {
	AspectRatio,
	Box,
	Flex,
	Heading,
	HStack,
	Icon,
	Link as ChakraLink,
	SimpleGrid,
	Skeleton,
	Stack,
	useColorModeValue
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import React from "react";
import {BookCard} from "../../../components/BookCard";
import {FaArrowRight} from "react-icons/fa";
import {useGetLibraryBooksQuery} from "../../../services/backendApi";

const BooksByQuery = ({books, loading}) => {
	if (loading) {
		return (
			<SimpleGrid
				columns={{
					base: 4,
					md: 5,
					lg: 6,
				}}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({ length: 12 }).map((_, index) => (
					<AspectRatio ratio={1 / 1.5}>
						<Skeleton key={index} />
					</AspectRatio>
				))}
			</SimpleGrid>
		);
	} else if (books) {
		return(
			<SimpleGrid
				columns={{
					base: 2,
					sm: 2,
					md: 4,
					lg: 6,
					xl: 8,
				}}
				gap={{
					base: '8',
					md: '6',
					lg: '8',
				}}
			>
				{books.map((book) => {
					return (
						<BookCard book={book} key={book.id}  />
					)
				})}
			</SimpleGrid>
		)


	}
}

const ContentSection = ({title, moreUrl, sectionToDisplay}) => {
	return (
		<Box
			mx="auto"
			px={{
				base: '4',
				md: '8',
				lg: '12',
			}}
			py={{
				base: '6',
				md: '8',
				lg: '12',
			}}
		>
			<Stack
				spacing={{
					base: '6',
					md: '8',
					lg: '12',
				}}
			>
				<Flex
					justify="space-between"
					align={{
						base: 'start',
						md: 'center',
					}}
					direction={{
						base: 'column',
						md: 'row',
					}}
				>
					<Heading
						size="sm"
						mb={{
							base: '3',
							md: '0',
						}}
					>
						{title}
					</Heading>
					<HStack
						spacing={{
							base: '2',
							md: '3',
						}}
					>
						<ChakraLink as={Link} to={moreUrl} fontWeight="semibold" color={useColorModeValue('brand.500', 'brand.300')}>
							See all
						</ChakraLink>
						<Icon
							as={FaArrowRight}
							color={useColorModeValue('brand.500', 'brand.300')}
							fontSize={{
								base: 'sm',
								md: 'md',
							}}
						/>
					</HStack>
				</Flex>
				{sectionToDisplay}
			</Stack>
		</Box>
	)
}

const Home = () => {
	const { data: recentlyReadData, isLoading: recentBooksLoading, isError: recentBooksError } = useGetLibraryBooksQuery({filter: 'recently_read', page: 1})
	const recentlyReadBooks = recentlyReadData?.data;

	const { data: queueBooksData, isLoading: queueBooksLoading, isError: queueBooksError } = useGetLibraryBooksQuery({filter: 'queue', page: 1})
	const queueBooks = queueBooksData?.data;

	const { data: finishedBooksData, isLoading: finishedBooksLoading, isError: finishedBooksError } = useGetLibraryBooksQuery({filter: 'finished', page: 1})
	const finishedBooks = finishedBooksData?.data;

	return (
		<>
			<ContentSection title="Currently Reading" moreUrl="/library/history" sectionToDisplay={
				<BooksByQuery books={recentlyReadBooks} loading={recentBooksLoading} key={"recently_read"} />
			} />

			<ContentSection title="Your Reading List" moreUrl="/library/queue" sectionToDisplay={
				<BooksByQuery books={queueBooks} loading={queueBooksLoading} key={"queue"} />
			} />

			<ContentSection title="Finished" moreUrl="/library/finished" sectionToDisplay={
				<BooksByQuery books={finishedBooks} loading={finishedBooksLoading} key={"finished"} />
			} />
		</>
	);
}

export default Home
