import {AspectRatio, Box, Container, Heading, SimpleGrid, Skeleton, Stack, Text} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {useGetBooksByCategoryQuery, useGetCategoryQuery} from "../../../services/backendApi";
import React, {useState} from "react";
import {BookCard} from "../../../components/BookCard";
import {Pagination} from "../../../components/Pagination";
import {BooksGrid} from "../components/BooksGrid";

const BooksByCategory = ({categoryId}) => {
	const [pageQuery, setPageQuery] = useState(1);
	const { data: booksData, isLoading, isFetching, isError } = useGetBooksByCategoryQuery({categoryId: categoryId, page: pageQuery});
	const books = booksData?.data;
	const meta = booksData?.meta;

	if (isLoading || isFetching) {
		return (
			<SimpleGrid
				columns={{
					base: 4,
					md: 5,
					lg: 6,
				}}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({ length: 12 }).map((_, index) => (
					<AspectRatio ratio={1 / 1.5}>
						<Skeleton key={index} />
					</AspectRatio>

				))}
			</SimpleGrid>
		);
	} else if (books) {
		return(
			<Box bg="bg.surface">
				<SimpleGrid
					columns={{
						base: 4,
						md: 5,
						lg: 6,
					}}
					gap={{
						base: '4',
						md: '6',
						lg: '8',
					}}
				>
					{books.map((book) => {
						return (
							<BookCard book={book}/>
						)
					})}

				</SimpleGrid>
				<Container
					py={{
						base: '12',
						md: '16',
					}}
				>
					<Pagination
						count={meta?.total_pages}
						pageSize={1}
						siblingCount={2}
						page={pageQuery}
						onChange={(e) => setPageQuery(e.page)}
					/>
				</Container>
			</Box>
		)

	}
}

const Category = () => {
	const { id: categoryId } = useParams();

	const { data: categoryData, isLoading: categoriesLoading, isError: categoriesError } = useGetCategoryQuery(categoryId)
	const category = categoryData?.data;

	if (categoriesLoading) {
		return (
			<Box bg="bg.surface">
				<Container py={{ base: '12' }}>
					<Text>Loading...</Text>
				</Container>
			</Box>
		)
	} else if (categoryData) {
		return (
			<Box bg="bg.surface">
				<Container py={{ base: '12' }}>
					<Stack spacing={{ base: '12', md: '16' }}>
						<Stack direction="row" justify="space-between">
							<Stack spacing={{ base: '4', md: '5' }}>
								<Stack spacing="3">
									<Link to={`/explore/categories`}>
										<Text color="accent" fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
											Categories
										</Text>
									</Link>
									<Heading size={{ base: 'sm', md: 'md' }}>{category.attributes.name}</Heading>
								</Stack>
								<Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
									{category.attributes.count} books in this category.
								</Text>
							</Stack>
						</Stack>
						<BooksGrid categoryId={categoryId} />
					</Stack>
				</Container>
			</Box>
		);
	}

}

export default Category
