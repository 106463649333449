import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {backendApi} from "../../services/backendApi";
import {GtagInitializerForUser} from "../../utils/gtag";

const tokenKey = '"authenticationToken"'


export const updateCredentialsAndCheck = createAsyncThunk('authReducer/updateCredentialsAndCheck', async (args,{dispatch}) => {
	if (args.value) {
		dispatch(setCredentials(args))
		await backendApi.endpoints.getUserProfile.initiate(undefined, {}).then((data) => {
		}).catch(err => {

		})
	} else {
		dispatch(setLoggedOut())
		window.location.reload();
	}
})

const initialState = {
	userToken: null || localStorage.getItem(tokenKey),
	user: null,
	account: null,
	loading: false,
	success: false
};

const authReducer = createSlice({
	name: 'authReducer',
	initialState,
	reducers: {
		setCredentials: (state, action) => {
			const newToken = action.payload.value
			localStorage.setItem(tokenKey, newToken);
			state.userToken = newToken
		},
		setLoggedOut: (state, action) => {
			localStorage.removeItem(tokenKey);
			state.userToken = null
			state.user = null
			state.loading = false
			state.success = false
		}
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			backendApi.endpoints.getUserProfile.matchPending,
			(state, { payload }) => {
				state.loading = true
			}
		).addMatcher(
			backendApi.endpoints.getUserProfile.matchFulfilled,
			(state, { payload }) => {
				state.user = payload.data
				state.account = payload.included?.[0]
				state.success = true
				state.loading = false
				GtagInitializerForUser(state.user.id)
			}
		).addMatcher(
			backendApi.endpoints.getUserProfile.matchRejected,
			(state, { payload }) => {
				if (payload.status === 401) {
					localStorage.removeItem(tokenKey);
					state.userToken = null
				}
				state.user = null
				state.account = null
				state.success = false
				state.loading = false
			},
		)
	},
});

export const { setCredentials, setLoggedOut} = authReducer.actions;
export default authReducer.reducer;

export const userAuthenticated = (state) => {
	return state.authReducer.success
}

export const getUser = (state) => {
	if (state.authReducer.user) {
		return state.authReducer.user
	} else {
		return null
	}
}
export const getUserPhoto = (state) => {
	if (state.authReducer.user) {
		return state.authReducer.user.attributes.avatar_url
	} else {
		return null
	}
}

export const getUserEmail = (state) => {
	if (state.authReducer.user) {
		return state.authReducer.user.attributes.email
	} else {
		return null
	}
}

export const getUserName = (state) => {
	if (state.authReducer.user) {
		return state.authReducer.user.attributes.name
	} else {
		return null
	}
}

export const getAccount = (state) => {
	if (state.authReducer.account) {
		return state.authReducer.account
	} else {
		return null
	}
}