import {Text} from "@chakra-ui/react";

function Collections() {
	return (
		<>
			<Text>Collections</Text>

		</>
	);
}

export default Collections
