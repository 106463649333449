import {Box, Container, Heading, HStack, SimpleGrid, Skeleton, Stack, Text,} from '@chakra-ui/react'
import {useGetCategoriesQuery} from "../../../services/backendApi";
import React from "react";
import {Link} from "react-router-dom";

const CategoriesContent = () => {
	const { data: categoriesData, isLoading: categoriesLoading, isError: categoriesError } = useGetCategoriesQuery()
	const categories = categoriesData?.data;

	const resultsToDisplayPerColumn = {
		base: 2,
		sm: 2,
		md: 3,
		lg: 3,
		xl: 4,
	}

	if (categoriesLoading) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({ length: 50 }).map((_, index) => (
					<Skeleton key={index} height="20px" />
				))}
			</SimpleGrid>
		);
	} else if (categories) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{
					categories.map((category) => {
						return (
							<React.Fragment key={category.id}>
								<Link to={`/explore/category/${category.id}`}>
									<Box
										padding={"10px"}
										py={"20px"}
										bgGradient='white'
										border='1px'
										borderRadius="md"
										borderColor='gray.200'
										_hover={{
											bg: "accent",
											color: "white",
											cursor: "pointer",
										}}
									>
										<HStack justifyContent="center">
											<Text
												_hover={{
													color: "white",
												}}
												fontSize="md"
												fontWeight="semibold"
												align="center"
												overflow="hidden"
												whiteSpace="nowrap"
												textOverflow="ellipsis"
												maxWidth="100%"
											>
												{category.attributes.name}
											</Text>
											<Text>({category.attributes.count})</Text>
										</HStack>


									</Box>
								</Link>
							</React.Fragment>
						)
					})
				}
			</SimpleGrid>
		)
	}
}

function Categories() {
	return (
		<Box bg="bg.surface">
			<Container py={{ base: '12' }}>
				<Stack spacing={{ base: '12', md: '16' }}>
					<Stack direction="row" justify="space-between">
						<Stack spacing={{ base: '4', md: '5' }}>
							<Stack spacing="3">
								<Link to={`/explore`}>
									<Text color="accent" fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
										Explore
									</Text>
								</Link>
								<Heading size={{ base: 'sm', md: 'md' }}>Categories</Heading>
							</Stack>
							<Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
								Discover books by category
							</Text>
						</Stack>
					</Stack>
					<CategoriesContent />
				</Stack>
			</Container>
		</Box>
	);
}

export default Categories
