import {Box, Image} from '@chakra-ui/react'
import darkLogo from "../images/SkimwiseLogo_ColorLogo_BlackWordmark.svg";
import whiteLogo from "../images/SkimwiseLogo_WhiteLogo_WhiteWordmark.svg";


export const Logo = ({logoColor, ...props}) => {
	if (logoColor === 'white') {
		return <Box {...props} maxWidth={"150px"}>
			<Image src={whiteLogo} alt="NameLogo" objectFit="cover"/>
		</Box>
	} else {
		return <Box {...props} maxWidth={"150px"}>
			<Image src={darkLogo} alt="NameLogo" objectFit="cover"/>
		</Box>
	}
}