import {
	Box,
	Flex,
	HStack,
	Icon,
	Stack,
	Text,
	Tooltip,
	useClipboard,
	useColorModeValue,
	useToast,
	VStack
} from '@chakra-ui/react'
import React from "react";
import {BookCoverImage} from "../../../components/BookCoverImage";
import {Link} from "react-router-dom";
import {FiShare, FiShoppingCart} from "react-icons/fi";

export const CardWithAvatar = (props) => {
	const { book, children, ...rest } = props
	const iconColor = useColorModeValue('gray.600', 'gray.400')
	const { onCopy, value, setValue, hasCopied } = useClipboard(`https://app.skimwise.com/books/${book.id}`);
	const toast = useToast();

	const handleCopyClick = () => {
		onCopy();
		toast({
			title: "URL copied to clipboard!",
			status: "success",
			duration: 2000,
			isClosable: true,
			position: "top"
		});
	};

	return (
		<Flex
			direction="column"
			alignItems="center"
			// rounded="md"
			position="relative"
			// bg={useColorModeValue('white', 'gray.700')}
			// shadow={{
			// 	md: 'base',
			// }}
			{...rest}
		>
			{/*<Box position="absolute" inset="0" height="20" bg="pink.600" roundedTop="inherit" />*/}
			{/*<Avatar size="xl" src={book.attributes} />*/}

			<Stack spacing="4">
				<Box>
					<Box width="40%" mx={"auto"}>
						<Link to={`/books/${book.id}`}>
							<BookCoverImage
								borderRadius="md"
								minWidth={"300px"}
								book={book}
								display={{
									base: 'initial',
								}}
							/>
						</Link>
					</Box>
				</Box>


				<VStack flex={"1"}>
					<Text textAlign="Center" fontWeight="bold">{book.attributes.title}</Text>

					{book.attributes.amazon_url &&
						<Flex
							direction="column"
							alignItems="center"
							position={"relative"}
						>
							<HStack >
								<Icon as={FiShoppingCart} fontSize="xl" color="gray.400" />
								<Link to={book.attributes.amazon_url} target={"_blank"}>
									<Text
										fontSize="sm"
										fontWeight="medium"
										color={iconColor}
									>
										Buy on Amazon
									</Text>
								</Link>

							</HStack>
						</Flex>

					}
					<Flex
						direction="column"
						alignItems="center"
						position={"relative"}
						pt={"5px"}
					>
						<Tooltip hasArrow label="Copy URL" bg='gray.300' color='black' placement='right'>
							<HStack>
								<Icon as={FiShare} fontSize="xl" color="gray.400" />
								<Link onClick={handleCopyClick}
								>
									<Text
										fontSize="sm"
										fontWeight="medium"
										color={iconColor}
									>
										Share with friends
									</Text>
								</Link>

							</HStack>
						</Tooltip>
					</Flex>
				</VStack>
			</Stack>
		</Flex>
	)
}