import {Outlet} from 'react-router-dom';
import {Box} from "@chakra-ui/react";
import {Navbar} from "../../components/navbar";

function UserPages() {
	return (
		<>
			<Box as="section">
				<Navbar />
				<Box minHeight={"120vh"} bg={"gray.50"}>
					<Outlet />
				</Box>
			</Box>
		</>
	);
}

export default UserPages
