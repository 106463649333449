import {Box, HStack, Link as ChakraLink, Stack, Text, Tooltip} from '@chakra-ui/react'
import React from "react"
import {Link} from "react-router-dom";
import {BookCoverImage} from "./BookCoverImage";

export const BookRow = (props) => {
	const { book, otherFunction, rootProps } = props

	const Title = () => {
		return(
			<Tooltip hasArrow label={book.attributes.title} bg='gray.300' color='black' >
				<Text
					as={'b'}
					fontSize={"md"}
					noOfLines={3}
					css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
				>
					{book.attributes.title}
				</Text>
			</Tooltip>
		)
	}

	const SecondaryTitle = () => {
		return(
			<Text
				fontSize={"sm"}
				noOfLines={2}
				css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
			>
				{book.attributes.secondary_title}
			</Text>
		)
	}

	const Authors = () => {
		return(
			<Text
				fontSize={"xs"}
				pt={"10px"}
				noOfLines={1}
				css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 1, WebkitBoxOrient: 'vertical' }}
			>
				{book.attributes.authors}
			</Text>
		)
	}

	const ReadLength = ({book}) => {
		return(
			<Text
				fontSize={"xs"}
				noOfLines={1}
				pt={"10px"}
				css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
				color={'gray.500'}
			>
				{book.attributes.read_length} min
			</Text>
		)
	}

	const handleClick = () => {
		if (otherFunction) {
			otherFunction();
		}
	};


	return (
		<ChakraLink as={Link} to={`/books/${book.id}`} onClick={handleClick}>
			<Box {...rootProps}>
				<Stack fontSize="sm" spacing="4">
					<Stack direction="row" justify="space-between" spacing="4">
						<HStack spacing="3" align={"start"}>
							<Box>
								<Box width={"90px"} mr={"15px"}>
									<BookCoverImage
										borderRadius="sm"
										_hover={{}}
										book={book} />
								</Box>
							</Box>
							<Box>
								{ book.attributes.title &&
									<Title />
								}

								{ book.attributes.secondary_title && <SecondaryTitle />}

								{ book.attributes.authors && <Authors /> }
								{ book.attributes.read_length && <ReadLength book={book} />}
							</Box>
						</HStack>
					</Stack>
				</Stack>
			</Box>


		</ChakraLink>

	)
}