import {Box, Flex, Heading, Image, Link, Stack, Text, useColorModeValue,} from '@chakra-ui/react'
import {useGetFreeBookQuery} from "../../../services/backendApi";
import React from "react";

export const FreeBookPanel = () => {
	const {data: bookData, isLoading, isError} = useGetFreeBookQuery()
	const book = bookData?.data;
	const boxBgColor = useColorModeValue('white', 'gray.800')

	if (book) {
		return (
			<Box position="relative">
				<Image
					src={book.attributes.cover_photo_url}
					alt={book.attributes.title}
					objectFit="cover"
					width="100%"
					height="md"
				/>
				<Box position="absolute" boxSize="full" inset="0">
					<Flex
						direction="column-reverse"
						height="full"
						maxW="7xl"
						mx="auto"
						px={{ base: '4', md: '8', lg: '12' }}
						py={{ base: '6', md: '8', lg: '12' }}
					>
						<Box
							bg={boxBgColor}
							alignSelf={{ md: 'start' }}
							p={{ base: '5', md: '8' }}
							minW={{ md: 'lg' }}
						>
							<Stack spacing="5">
								<Stack spacing="1">
									<Text fontSize="xs" fontWeight={"600"}>
										TODAY'S FREE BOOK
									</Text>
									<Heading size="sm" fontWeight="normal">
										{book.attributes.title}
									</Heading>
									<Heading size="xs" fontWeight="normal" mt={"20px"}>
										{book.attributes.authors}
									</Heading>
								</Stack>
								<Link fontWeight="bold" textDecoration="underline" color={"accent.100"}
								      href={`/books/${book.id}`}
								>
									Read it now
								</Link>
							</Stack>
						</Box>
					</Flex>
				</Box>
			</Box>

			// <Box
			// 	maxW="7xl"
			// 	mx="auto"
			// 	px={{
			// 		base: '0',
			// 		lg: '12',
			// 	}}
			// 	py={{
			// 		base: '0',
			// 		lg: '12',
			// 	}}
			// >
			// 	<Stack
			// 		direction={{
			// 			base: 'column-reverse',
			// 			lg: 'row',
			// 		}}
			// 		spacing={{
			// 			base: '0',
			// 			lg: '20',
			// 		}}
			// 	>
			// 		<Box
			// 			width={{
			// 				lg: 'sm',
			// 			}}
			// 			transform={{
			// 				base: 'translateY(-50%)',
			// 				lg: 'none',
			// 			}}
			// 			bg={{
			// 				base: 'transparent',
			// 			}}
			// 			mx={{
			// 				base: '6',
			// 				md: '8',
			// 				lg: '0',
			// 			}}
			// 			px={{
			// 				base: '6',
			// 				md: '8',
			// 				lg: '0',
			// 			}}
			// 			py={{
			// 				base: '6',
			// 				md: '8',
			// 				lg: '12',
			// 			}}
			// 		>
			// 			<Stack
			// 				spacing={{
			// 					base: '8',
			// 					lg: '10',
			// 				}}
			// 			>
			// 				<Stack
			// 					spacing={{
			// 						base: '2',
			// 						lg: '4',
			// 					}}
			// 				>
			// 					<Heading size="md">
			// 						Today's free book
			// 					</Heading>
			// 					<Heading size="sm" fontWeight="normal">
			// 						{book.attributes.title}
			// 					</Heading>
			// 					<Heading size="xs" fontWeight="normal" mt={"20px"}>
			// 						By {book.attributes.authors}
			// 					</Heading>
			// 					<Text
			// 						fontSize={"md"}
			// 						noOfLines={2}
			// 						pt={"10px"}
			// 						css={{ display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
			// 					>
			// 						{book.attributes.simple_summary}
			// 					</Text>
			// 				</Stack>
			// 				<HStack spacing="3">
			// 					<Link color={"brand.300"} fontWeight="bold" fontSize="lg"
			// 					      href={`/books/${book.id}`}
			// 					>
			// 						Read now
			// 					</Link>
			// 					<Link
			// 					      href={`/books/${book.id}`}
			// 					      pt={"4px"}
			// 					>
			// 						<Icon color={"brand.300"} as={FaArrowRight}/>
			// 					</Link>
			//
			// 				</HStack>
			// 			</Stack>
			// 		</Box>
			// 		<Flex flex="1" overflow="hidden">
			// 			<Box bg="lightAccent.100" p={"20px"}>
			// 				<HStack>
			// 					<BookCoverImage
			// 						borderRadius="sm"
			// 						minWidth={"200px"}
			// 						book={book} />
			// 				</HStack>
			// 			</Box>
			//
			//
			// 			{/*<Image*/}
			// 			{/*	src="https://images.unsplash.com/photo-1589156229687-496a31ad1d1f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"*/}
			// 			{/*	alt="Lovely Image"*/}
			// 			{/*	fallback={<Skeleton/>}*/}
			// 			{/*	maxH="450px"*/}
			// 			{/*	minW="300px"*/}
			// 			{/*	objectFit="cover"*/}
			// 			{/*	flex="1"*/}
			// 			{/*/>*/}
			// 			{/*<Image*/}
			// 			{/*	display={{*/}
			// 			{/*		base: 'none',*/}
			// 			{/*		sm: 'initial',*/}
			// 			{/*	}}*/}
			// 			{/*	src="https://images.unsplash.com/photo-1589156206699-bc21e38c8a7d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80"*/}
			// 			{/*	alt="Lovely Image"*/}
			// 			{/*	fallback={<Skeleton/>}*/}
			// 			{/*	maxH="450px"*/}
			// 			{/*	objectFit="cover"*/}
			// 			{/*/>*/}
			// 		</Flex>
			// 	</Stack>
			// </Box>
		)
	}


}