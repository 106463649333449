import {AspectRatio, Box, Container, Heading, SimpleGrid, Skeleton, Stack, Text} from "@chakra-ui/react";
import {useGetLibraryBooksQuery} from "../../../services/backendApi";
import {BookCard} from "../../../components/BookCard";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {Pagination} from "../../../components/Pagination";

const Books = () => {
	const [pageQuery, setPageQuery] = useState(1);
	const { data: booksData, isLoading, isFetching, isError } = useGetLibraryBooksQuery({
		filter: 'finished',
		page: pageQuery,
	});
	const books = booksData?.data;
	const meta = booksData?.meta;

	if (isLoading || isFetching) {
		return (
			<SimpleGrid
				columns={{
					base: 4,
					md: 5,
					lg: 6,
				}}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({length: 12}).map((_, index) => (
					<AspectRatio ratio={1 / 1.5}>
						<Skeleton key={index}/>
					</AspectRatio>
				))}
			</SimpleGrid>
		);
	} else if (books) {
		return (
			<Box bg="bg.surface">
				<SimpleGrid
					columns={{
						base: 4,
						md: 5,
						lg: 6,
					}}
					gap={{
						base: '4',
						md: '6',
						lg: '8',
					}}
				>
					{books.map((book) => {
						return (
							<BookCard book={book}/>
						)
					})}

				</SimpleGrid>
				<Container
					py={{
						base: '12',
						md: '16',
					}}
				>
					<Pagination
						count={meta?.total_pages}
						pageSize={1}
						siblingCount={2}
						page={pageQuery}
						onChange={(e) => setPageQuery(e.page)}
					/>
				</Container>
			</Box>
		)
	}
}

const Finished = () => {
	return (
		<Box bg="bg.surface">
			<Container py={{ base: '12' }}>
				<Stack spacing={{ base: '12', md: '16' }}>
					<Stack direction="row" justify="space-between">
						<Stack spacing={{ base: '4', md: '5' }}>
							<Stack spacing="3">
								<Link to={`/library`}>
									<Text color="accent" fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
										Library
									</Text>
								</Link>
								<Heading size={{ base: 'sm', md: 'md' }}>
									Finished
								</Heading>
							</Stack>
							<Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
								Books you've already finished reading
							</Text>
						</Stack>
					</Stack>
					<Books />
				</Stack>
			</Container>
		</Box>
	);
}

export default Finished