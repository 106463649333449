import {Box, Container, Heading, Link, Stack, Text} from '@chakra-ui/react'
import {Logo} from "../../../components/Logo";
import LoginWithGoogle from "../../../components/LoginWithGoogle";

export const LoginPage = () => (
	<Box bgGradient={'linear(to-br, lightAccent.300, brand.100)'} py={{ base: '12', md: '24' }} minHeight="100vh" >
		<Container
			maxW="md"
			py={{ base: '0', sm: '8' }}
			px={{ base: '4', sm: '10' }}
			bg={{ base: 'transparent', sm: 'bg-surface' }}
			boxShadow={{ base: 'none', sm: 'xl' }}
			borderRadius={{ base: 'none', sm: 'xl' }}
		>
			<Stack spacing="8">
				<Stack spacing="6" align="center">
					<Link href={"/"}>
						<Logo />
					</Link>
					<Stack spacing="3" textAlign="center">
						<Heading size="xs">Log in to your account</Heading>
						<Text>
							Don't have an account? <Link href="/signup" color={"brand.300"}>Sign up</Link>
						</Text>
					</Stack>
				</Stack>
				<Stack spacing="6">
					<LoginWithGoogle />
				</Stack>
			</Stack>
		</Container>
	</Box>
)