import {
	Box,
	Button,
	Heading,
	HStack,
	Icon,
	Skeleton,
	SkeletonText,
	Stack,
	Tag,
	Text,
	Tooltip,
	useClipboard,
	useColorModeValue,
	useToast,
	VStack,
	Wrap,
} from '@chakra-ui/react'
import {HiOutlineStar} from 'react-icons/hi'
import {Link, useParams} from "react-router-dom";
import {useGetBookQuery} from "../../../services/backendApi";
import {BookCoverImage} from "../../../components/BookCoverImage";
import {FiShare, FiShoppingCart} from "react-icons/fi";
import React from "react";
import {MarkAsQueuedButton, MarkAsUnqueuedButton} from "../../../components/UserBookButtons";
import {useSelector} from "react-redux";
import {getAccount} from "../../auth/authReducer";

export const Card = (props) => (
	<Box
		mx="auto"
		bg={useColorModeValue('white', 'gray.700')}
		rounded={{
			md: 'xl',
		}}
		padding="10"
		shadow={{
			md: 'base',
		}}
		px={{
			base: '6',
			md: '8',
		}}
		{...props}
	/>
)


const FullAccessReadingButtons = ({book}) => {
	return(
		<>
			{ !book.attributes.currently_reading_section_id &&
				<Link to={`/reader/${book.id}/sections/${book.attributes.toc[0].id}`}>
					<Button
						colorScheme="brand"
						size={"lg"}
					>
						Read Now
					</Button>
				</Link>
			}
			{ book.attributes.currently_reading_section_id &&
				<Link to={`/reader/${book.id}/sections/${book.attributes.currently_reading_section_id}`}>
					<Button
						colorScheme="brand"
						size={"lg"}
					>
						Continue Reading ({book.attributes.currently_reading_percentage}% complete)
					</Button>

				</Link>
			}



			{ !book.attributes.queued &&
				<MarkAsQueuedButton bookId={book.id}
				                    size={"lg"}
				                    ml={"10px"}
				/>
			}
			{ book.attributes.queued &&
				<MarkAsUnqueuedButton bookId={book.id}
				                      size={"lg"}
				                      ml={"10px"}
				/>
			}
		</>
	)
}

const SubscribeToRead = ({book}) => {
	return (
		<Link to={`/plan-selection`}>
			<Button
				colorScheme="brand"
				size={"lg"}
			>
				Subscribe to read
			</Button>

		</Link>
	)
}

const SignUpToRead = ({book}) => {
	return (
		<Link to={`/login`}>
			<Button
				colorScheme="brand"
				size={"lg"}
			>
				Create an account to read
			</Button>

		</Link>
	)
}

const ReadingButtons = ({book}) => {
	const account = useSelector(getAccount)

	if (account?.attributes?.status === 'active') {
		return (
			<FullAccessReadingButtons book={book}/>
		)
	} else {
		if (account) {
			if (book.attributes?.free_to_read) {
				return (
					<FullAccessReadingButtons book={book}/>
				)
			}	else {
				return (
					<SubscribeToRead book={book}/>
				)
			}
		} else {
			return (
				<SignUpToRead book={book}/>
			)
		}
	}
}

const BookSummary = ({book, categories}) => {
	const iconColor = useColorModeValue('gray.600', 'gray.400')
	const { onCopy, value, setValue, hasCopied } = useClipboard(`https://app.skimwise.com/books/${book.id}`);
	const toast = useToast();

	const handleCopyClick = () => {
		onCopy();
		toast({
			title: "URL copied to clipboard!",
			status: "success",
			duration: 2000,
			isClosable: true,
			position: "top"
		});
	};

	return(
		<Box as="section" maxW="6xl" mx="auto" py="12" px="30px">
			<Box>
				<Stack
					direction={{
						base: 'column',
						md: 'row',
					}}
					spacing={{
						base: '3',
						md: '100px',
					}}
					align="flex-start"
				>

					<Box>

						<Box mx={"auto"} justifyContent="center"
						     display={{
							     base: 'flex',
							     md: 'none',
						     }}

						     mb={"50px"}>
							<Box width={"200px"} >
								<BookCoverImage
									borderRadius="md"
									_hover={{}}
									book={book}
								/>
							</Box>
						</Box>



						<Stack
							spacing={{
								base: '1',
								md: '2',
							}}
							direction={{
								base: 'column',
								md: 'row',
							}}
						>
							<Heading size={{
								base: 'sm',
								lg: 'xl',
							}} fontWeight={"500"}>
								{book.attributes.title}
							</Heading>
						</Stack>
						<Box pt={"10px"}>
							<Heading size={{
													base: 'xs',
													lg: 'sm',
							}} fontWeight={"300"}>
								{book.attributes.secondary_title || "Secondary Title goes here"}
							</Heading>
						</Box>
						<Text mt="20px" fontSize={"xl"}>
							{book.attributes.authors || "Authors"}
						</Text>


						<Box mt={"50px"}>
							{<ReadingButtons book={book} />}
						</Box>

						<Box pt={"50px"}>
							<Heading size={"xs"}>
								Quick Look
							</Heading>
							<Box>
								{categories &&
									<Wrap shouldWrapChildren mt="5">
										{categories.map((category) => (
											<Link to={`/explore/category/${category.id}`} key={category.id}>
												<Tag color="inherit" px="3">
													{category.attributes.name}
												</Tag>
											</Link>
										))}
									</Wrap>
								}
							</Box>
							<Text whiteSpace="pre-wrap"
							      mt={"5"}
							      fontSize={"20px"}
							      lineHeight={"32px"}
							>
								{book.attributes.simple_summary}
							</Text>

						</Box>

					</Box>
					<Stack spacing="4">
						<BookCoverImage
							borderRadius="md"
							minWidth={"300px"}
							book={book}
							_hover={{}}
							display={{
								base: 'none',
								md: 'initial',
							}}
						/>


						<VStack mt={"30px"} align={"flex-start"}>
							{book.attributes.good_reads_url &&
								<Tooltip hasArrow label="View on GoodReads" bg='gray.300' color='black' placement='right'>
									<HStack>
										<Icon as={HiOutlineStar} fontSize="xl" color="gray.400" />
										<Link to={book.attributes.good_reads_url} target={"_blank"}>
											<Text
												fontSize="sm"
												fontWeight="medium"
												color={iconColor}
											>
												{book.attributes.average_rating} ({book.attributes.ratings_count.toLocaleString()} ratings)
											</Text>
										</Link>

									</HStack>
								</Tooltip>
							}
							{book.attributes.amazon_url &&
								<HStack>
									<Icon as={FiShoppingCart} fontSize="xl" color="gray.400" />
									<Link to={book.attributes.amazon_url} target={"_blank"}>
										<Text
											fontSize="sm"
											fontWeight="medium"
											color={iconColor}
										>
											Buy on Amazon
										</Text>
									</Link>

								</HStack>
							}
							<Tooltip hasArrow label="Copy URL" bg='gray.300' color='black' placement='right'>
								<HStack>
									<Icon as={FiShare} fontSize="xl" color="gray.400" />
									<Link onClick={handleCopyClick}
									>
										<Text
											fontSize="sm"
											fontWeight="medium"
											color={iconColor}
										>
											Share with friends
										</Text>
									</Link>

								</HStack>
							</Tooltip>
						</VStack>
					</Stack>
				</Stack>

			</Box>
		</Box>
	)
}


const BookSummaryLoading = () => {
	return(
		<Box as="section" maxW="6xl" mx="auto" py="12" px="30px">
			<Box>
				<Stack
					direction={{
						base: 'column',
						md: 'row',
					}}
					spacing={{
						base: '3',
						md: '100px',
					}}
					align="flex-start"
				>

					<Box>

						<Stack
							spacing={{
								base: '1',
								md: '2',
							}}
							direction={{
								base: 'column',
								md: 'row',
							}}
						>
							<Skeleton width={"100%"} height={"50px"} />
						</Stack>
						<Box pt={"10px"}>
							<Heading size={"sm"}>
								<Skeleton width={"100%"} height={"30px"} />
							</Heading>
						</Box>
						<Text mt="20px" fontSize={"xl"}>
							<Skeleton width={"50%"} height={"50px"} />
						</Text>


						<Box mt={"50px"}>
							<Skeleton width={"300px"} height={"50px"} />
						</Box>

						<Box pt={"50px"}>
							<Heading size={"xs"}>
								<Skeleton width={"50%"} height={"30px"} />
							</Heading>
							<Box>
								<Wrap shouldWrapChildren mt="5">
									<Skeleton width={"200px"} height={"50px"} />
									<Skeleton width={"200px"} height={"50px"} />
									<Skeleton width={"200px"} height={"50px"} />
									<Skeleton width={"200px"} height={"50px"} />
								</Wrap>
							</Box>
							<Text whiteSpace="pre-wrap"
							      mt={"5"}
							      fontSize={"20px"}
							      lineHeight={"32px"}
							>
								<SkeletonText mt='1' noOfLines={10} spacing='4' skeletonHeight='3' />
							</Text>

						</Box>

					</Box>
					<Stack spacing="4">
						<Skeleton width={"300px"} height={"500px"} />
					</Stack>
				</Stack>

			</Box>
		</Box>
	)
}

function Book() {
	const { id: bookId } = useParams();
	const { data: bookData, isLoading, isError } = useGetBookQuery(bookId)
	const book = bookData?.data;
	const categories = bookData?.included?.filter(item => item.type === 'category')

	if (book) {
		return (
			<>
				<BookSummary book={book} categories={categories}/>
			</>
		);
	} else {
		return (
			<>
				<BookSummaryLoading />
			</>
		)
	}


}

export default Book
