import {Box, Container, Heading, Stack, Text} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import React from "react";
import {BooksGrid} from "../components/BooksGrid";

const Search = () => {
	const { searchValue } = useParams();

	return (
		<Box bg="bg.surface">
			<Container py={{ base: '12' }}>
				<Stack spacing={{ base: '12', md: '16' }}>
					<Stack direction="row" justify="space-between">
						<Stack spacing={{ base: '4', md: '5' }}>
							<Stack spacing="3">
								<Link to={`/explore`}>
									<Text color="accent" fontWeight="semibold" fontSize={{ base: 'sm', md: 'md' }}>
										Search
									</Text>
								</Link>
								<Heading size={{ base: 'sm', md: 'md' }}>
									Results for "{searchValue}"
								</Heading>
							</Stack>
							<Text color="fg.muted" fontSize={{ base: 'lg', md: 'xl' }}>
								Here are the results for your search request
							</Text>
						</Stack>
					</Stack>
					<BooksGrid search={searchValue} />
				</Stack>
			</Container>
		</Box>
	);
}

export default Search