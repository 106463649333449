import {Outlet} from 'react-router-dom';
import {Box} from "@chakra-ui/react";
import React from "react";

function Reader() {
	return (
		<>
			<Box as="section" minHeight="100vh" overflowY="auto">
				<Outlet />
			</Box>
		</>
	);
}

export default Reader
