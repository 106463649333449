import {
	Box,
	Container,
	Stack,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useBreakpointValue,
	useColorModeValue,
} from "@chakra-ui/react";
import {NavLink as ReactRouterLink, useParams} from "react-router-dom";
import {Billing} from "./billing";

const SettingsRoutes =  {
	// notifications = "Notifications",
	// yourTeam = "Your Team",
	billing: "Billing",
}

const tabPanels = ["billing"];

export const SettingsPage = () => {
	const isMobile = useBreakpointValue({ base: true, md: false });
	const routes = Object.entries(SettingsRoutes)
	const { settingsPanel } = useParams();
	const foundIndex = tabPanels.findIndex((t) => t === settingsPanel);
	const tabPanelId = foundIndex === -1 ? 0 : foundIndex;
	return (
		<Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 8 }}>
			<Box
				bg="bg-surface"
				boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
				borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
			>
				<Stack spacing="3">
					<Box px={{ base: "4", md: "6" }} pt="5">
						<Stack
							direction={{ base: "column", md: "row" }}
							justify="space-between"
						>
							<Text fontSize="2xl" fontWeight="medium">
								Settings
							</Text>
						</Stack>
					</Box>
					<Box px={{ base: "4", md: "6" }} pb="5">
						<Tabs size={'lg'} index={tabPanelId}>
							<TabList>
								{routes.map(([route, title], idx) => (
									<Tab>
										<ReactRouterLink
											to={"/settings/" + tabPanels[idx]}
											key={route}
										>
											{title}
										</ReactRouterLink>
									</Tab>
								))}
								<TabIndicator />
							</TabList>

							<TabPanels>
								<TabPanel paddingTop={6}>
									<Billing />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Box>
				</Stack>
			</Box>
		</Container>
	);
};
