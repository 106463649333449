import {useSelector} from "react-redux";
import {getUser, getUserEmail, getUserName, getUserPhoto} from "../../views/auth/authReducer";
import {Avatar, Button, HStack, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text} from "@chakra-ui/react";
import {LogoutButton} from "../LogoutButton";
import {MdOutlineAttachMoney} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";

export const UserDesktopMenu = () => {
	const user = useSelector(getUser)
	const userPhoto = useSelector(getUserPhoto)
	const userEmail = useSelector(getUserName)
	const userName = useSelector(getUserEmail)
	const navigate = useNavigate()

	if (user) {
		return(
			<HStack spacing="4">
				<Menu>
					<MenuButton>
						<HStack>
							<Text mr={"10px"}>
								{userName}
							</Text>
							<Avatar boxSize="10" name={userEmail} src={userPhoto} />
						</HStack>
					</MenuButton>
					<MenuList>
						<MenuGroup title='Settings'>
							<MenuItem
								onClick={() => navigate('/settings/billing')}
								icon={<MdOutlineAttachMoney />} >
								Billing
							</MenuItem>
						</MenuGroup>
						<MenuGroup title='Logout'>
							<LogoutButton />
						</MenuGroup>
					</MenuList>
				</Menu>
			</HStack>
		)
	} else {
		return(
			<Button as={Link} to={"/login"}>Login</Button>
		)
	}

}