import {
	AspectRatio,
	Box,
	Flex,
	Heading,
	HStack,
	Icon,
	Link as ChakraLink,
	SimpleGrid,
	Skeleton,
	Stack,
	Text,
	useBreakpointValue,
	useColorModeValue
} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import {useGetBooksQuery, useGetCategoriesQuery} from "../../../services/backendApi";
import React from "react";
import {BookCard} from "../../../components/BookCard";
import {FaArrowRight} from "react-icons/fa";
import {FreeBookPanel} from "./FreeBookPanel";

const Categories = ({categories, categoriesLoading}) => {
	const resultsToDisplayPerColumn = {
		base: 2,
		sm: 2,
		md: 3,
		lg: 3,
		xl: 4,
	}

	const numberOfResults = useBreakpointValue({
			base: 4,
			sm: 4,
			md: 6,
			lg: 6,
			xl: 8,
		}
	);

	if (categoriesLoading) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({ length: numberOfResults }).map((_, index) => (
					<Skeleton key={index} height="40px" />
				))}
			</SimpleGrid>
		);
	} else if (categories) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{
					categories.slice(0, numberOfResults).map((category) => {
						return (
							<React.Fragment key={category.id}>
								<Link to={`/explore/category/${category.id}`}>
									<Box
										padding={"10px"}
										py={"20px"}
										bgGradient='white'
										border='1px'
										borderRadius="md"
										borderColor='gray.200'
										_hover={{
											bg: "accent",
											color: "white",
											cursor: "pointer",
										}}
									>
										<HStack justifyContent="center">
											<Text
												_hover={{
													color: "white",
												}}
												fontSize="md"
												fontWeight="semibold"
												align="center"
												overflow="hidden"
												whiteSpace="nowrap"
												textOverflow="ellipsis"
												maxWidth="100%"
											>
												{category.attributes.name}
											</Text>
											<Text>({category.attributes.count})</Text>
										</HStack>


									</Box>
								</Link>
							</React.Fragment>
						)
					})
				}
			</SimpleGrid>
		)
	}
}

const BooksByQuery = ({queryKey, books, loading}) => {
	const resultsToDisplayPerColumn = {
		base: 2,
		sm: 2,
		md: 4,
		lg: 6,
		xl: 8,
	}

	const numberOfResults = useBreakpointValue({
			base: 4,
			sm: 4,
			md: 4,
			lg: 6,
			xl: 8,
		}
	);

	if (loading) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({ length: numberOfResults }).map((_, index) => (
					<AspectRatio ratio={1 / 1.5}  key={`${queryKey}_loading_${index}`}>
						<Skeleton />
					</AspectRatio>
				))}
			</SimpleGrid>
		);
	} else if (books) {
		return(
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '8',
					md: '6',
					lg: '8',
				}}
			>
				{books.slice(0, numberOfResults).map((book) => {
					return (
						<BookCard book={book} key={`${queryKey}_book_${book.id}`}  />
					)
				})}
			</SimpleGrid>
		)


	}
}

const ContentSection = ({title, moreUrl, sectionToDisplay}) => {
	return (
		<Box
			mx="auto"
			px={{
				base: '4',
				md: '8',
				lg: '12',
			}}
			py={{
				base: '6',
				md: '8',
				lg: '12',
			}}
		>
			<Stack
				spacing={{
					base: '6',
					md: '8',
					lg: '12',
				}}
			>
				<Flex
					justify="space-between"
					align={{
						base: 'start',
						md: 'center',
					}}
					direction={{
						base: 'column',
						md: 'row',
					}}
				>
					<Heading
						size="sm"
						mb={{
							base: '3',
							md: '0',
						}}
					>
						{title}
					</Heading>
					<HStack
						spacing={{
							base: '2',
							md: '3',
						}}
					>
						<ChakraLink as={Link} to={moreUrl} fontWeight="semibold" color={useColorModeValue('brand.500', 'brand.300')}>
							See all
						</ChakraLink>
						<Icon
							as={FaArrowRight}
							color={useColorModeValue('brand.500', 'brand.300')}
							fontSize={{
								base: 'sm',
								md: 'md',
							}}
						/>
					</HStack>
				</Flex>
				{sectionToDisplay}
			</Stack>
		</Box>
	)
}

const Home = () => {
	const { data: categoriesData, isLoading: categoriesLoading, isError: categoriesError } = useGetCategoriesQuery()
	const categories = categoriesData?.data;

	const { data: recentlyAddedBooksData, isLoading: recentBooksLoading, isError: recentBooksError } = useGetBooksQuery({filter: 'most_recent', page: 1})
	const mostRecentBooks = recentlyAddedBooksData?.data;

	const { data: mostPopularBooksData, isLoading: mostPopularBooksLoading, isError: mostPopularBooksError } = useGetBooksQuery({filter: 'most_popular', page: 1})
	const mostPopularBooks = mostPopularBooksData?.data;

	const { data: freeToReadBooksData, isLoading: freeToReadBooksLoading, isError: freeToReadBooksError } = useGetBooksQuery({filter: 'free_to_read', page: 1})
	const freeToReadBooks = freeToReadBooksData?.data;

	return (
		<>
			<FreeBookPanel />

			<ContentSection title="By Category" moreUrl="/explore/categories" sectionToDisplay={
				<Categories categories={categories} categoriesLoading={categoriesLoading} />
			} />


			<ContentSection title="Free" moreUrl="/explore/free-to-read" sectionToDisplay={
				<BooksByQuery books={freeToReadBooks} loading={freeToReadBooksLoading} queryKey={"free_to_read"} />
			} />

			<ContentSection title="Most Popular" moreUrl="/explore/most-popular" sectionToDisplay={
				<BooksByQuery books={mostPopularBooks} loading={mostPopularBooksLoading} queryKey={"most_popular"} />
			} />


			<ContentSection title="Recently Added" moreUrl="/explore/recently-added" sectionToDisplay={
				<BooksByQuery books={mostRecentBooks} loading={recentBooksLoading} queryKey={"most_recent"} />
			} />

		</>
	);
}

export default Home
