import {Route, Routes} from "react-router-dom";
import DefaultLayout from "./layouts/default-layout";
import LoginWithRedirect from "./auth/LoginWithRedirect";
import {useSelector} from "react-redux";
import {useGetUserProfileQuery} from "../services/backendApi";
import React from "react";
// import PrivateRoute from "./PrivateRoute";
import exploreRoutesList from "./explore/explore-routes-list";
import Explore from "./explore";
import Collection from "./explore/collection";
import Reader from "./reader";
import Books from "./books";
import Book from "./books/book";
import Category from "./explore/category";
import ReaderView from "./reader/reader_view";
import {Section} from "./reader/reader_view/Section";
import Home from "./explore/home";
import libraryRoutesList from "./library/library-routes-list";
import PrivateRoute from "./PrivateRoute";
import {PlanSelection} from "./user/plan-selection";
import {SettingsPage} from "./user/settings";
import UserPages from "./user";
import Search from "./explore/search";
import {LoginPage} from "./static-pages/login-page";
import {SignupPage} from "./static-pages/sign-up";

const Router = () => {
	const userToken = useSelector(state => state.authReducer.userToken)
	const { isSuccess, isLoading } = useGetUserProfileQuery(undefined, {
		skip: !userToken, // Skip the query if userToken is not available
	});

	const exploreRoutes = exploreRoutesList.map(({ path, title, element }, index) => {
		if (index === 0) {
			return <Route index key={title} element={element} />;
		} else {
			return <Route key={title} path={`${path}`} element={element} />;
		}
	});

	const libraryRoutes = libraryRoutesList.map(({ path, title, element }, index) => {
		if (index === 0) {
			return <Route index key={title} element={element} />;
		} else {
			return <Route key={title} path={`${path}`} element={element} />;
		}
	});

	return <Routes>
		<Route element={<DefaultLayout />}>
			<Route path="/login" element={<LoginPage />} />
			<Route path="/signup" element={<SignupPage />} />

			<Route path="/login/:value" element={<LoginWithRedirect />} />

			<Route path={"/"} element={<Explore />}>
				<Route index key={'ExploreHome'} element={<Home />} />
			</Route>

			<Route path={"/explore"} element={<Explore />}>
				{exploreRoutes}
				<Route path={"search/:searchValue"} element={<Search />} />
				<Route path={"collection/:id"} element={<Collection />} />
				<Route path={"category/:id"} element={<Category />} />
			</Route>
			<Route element={<PrivateRoute />}>
				<Route path={"/library"} element={<Explore />}>
					{libraryRoutes}
				</Route>
				<Route element={<UserPages />}>
					<Route path={"/settings"} element={<SettingsPage />} />
					<Route path={"/settings/:settingsPanel"} element={<SettingsPage />} />
				</Route>
				<Route path={"/plan-selection"} element={<PlanSelection />} />
				<Route element={<Reader />}>
					<Route path={"reader/:bookId"} element={<ReaderView />}>
						<Route path={"sections/:sectionId"} element={<Section />} />
					</Route>
				</Route>
			</Route>

			<Route element={<Books />}>
				<Route path={"/books/:id"} element={<Book />} />
			</Route>

		</Route>
	</Routes>
};

export default Router;