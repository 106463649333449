import {Pagination as ArkPagination, PaginationNextPageTrigger, PaginationPrevPageTrigger,} from '@ark-ui/react'
import {Button, Center, IconButton, List, ListItem, Text, useBreakpointValue, VisuallyHidden,} from '@chakra-ui/react'
import {FiArrowLeft, FiArrowRight} from 'react-icons/fi'

export const Pagination = (props) => {
	const isMobile = useBreakpointValue({
		base: true,
		md: false,
	})
	return (
		<ArkPagination {...props}>
			{({ pages, page }) => (
				<List gap="1" display="flex" justifyContent="space-between">
					<ListItem>
						<PaginationPrevPageTrigger asChild>
							{isMobile ? (
								<IconButton variant="secondary" icon={<FiArrowLeft />} aria-label="Previous Page" />
							) : (
								<Button variant="secondary">
									Previous <VisuallyHidden>Page</VisuallyHidden>
								</Button>
							)}
						</PaginationPrevPageTrigger>
					</ListItem>
					<ListItem as={Center}>
						<Text fontWeight="medium" color="fg.emphasized">
							{page} of {pages.length }
						</Text>
					</ListItem>

					<ListItem>
						<PaginationNextPageTrigger asChild>
							{isMobile ? (
								<IconButton variant="secondary" icon={<FiArrowRight />} aria-label="Next Page" />
							) : (
								<Button variant="secondary">
									Next <VisuallyHidden>Page</VisuallyHidden>
								</Button>
							)}
						</PaginationNextPageTrigger>
					</ListItem>
				</List>
			)}
		</ArkPagination>
	)
}