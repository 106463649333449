import {Text} from "@chakra-ui/react";
import {useParams} from "react-router-dom";

function Collection() {
	const { id } = useParams();

	return (
		<>
			<Text>Collection</Text>
			<Text>{id}</Text>
		</>
	);
}

export default Collection
