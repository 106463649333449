import {AspectRatio, Box, Container, SimpleGrid, Skeleton} from "@chakra-ui/react";
import {useGetBooksByCategoryQuery, useGetBooksQuery} from "../../../services/backendApi";
import {BookCard} from "../../../components/BookCard";
import React, {useEffect, useState} from "react";
import {Pagination} from "../../../components/Pagination";

export const BooksGrid = ({filter, search, categoryId}) => {
	const [pageQuery, setPageQuery] = useState(1);
	let queryHook;
	let queryOptions;

	if (categoryId) {
		queryHook = useGetBooksByCategoryQuery;
		queryOptions = { categoryId, page: pageQuery };
	} else {
		queryHook = useGetBooksQuery;
		queryOptions = { filter, page: pageQuery, search };
	}

	const { data: booksData, isLoading, isFetching, isError } = queryHook(queryOptions);

	const books = booksData?.data;
	const meta = booksData?.meta;

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [pageQuery]);

	const resultsToDisplayPerColumn = {
		base: 3,
		sm: 3,
		md: 6,
		lg: 8,
		xl: 8,
	}

	if (isLoading || isFetching) {
		return (
			<SimpleGrid
				columns={resultsToDisplayPerColumn}
				gap={{
					base: '4',
					md: '6',
					lg: '8',
				}}
			>
				{Array.from({length: 24}).map((_, index) => (
					<AspectRatio ratio={1 / 2} key={`${index}`}>
						<Skeleton/>
					</AspectRatio>
				))}
			</SimpleGrid>
		);
	} else if (books) {
		return (
			<Box bg="bg.surface">
				<SimpleGrid
					columns={resultsToDisplayPerColumn}
					gap={{
						base: '4',
						md: '6',
						lg: '8',
					}}
				>
					{books.map((book) => {
						return (
							<BookCard book={book} key={`${book.id}`}/>
						)
					})}

				</SimpleGrid>
				<Container
					py={{
						base: '12',
						md: '16',
					}}
				>
					<Pagination
						count={meta?.total_pages}
						pageSize={1}
						siblingCount={2}
						page={pageQuery}
						onChange={(e) => setPageQuery(e.page)}
					/>
				</Container>
			</Box>
		)
	}
}