import {Button, Divider, Flex, Stack} from '@chakra-ui/react'
import {FiSearch} from 'react-icons/fi'
import {Logo} from '../Logo'
import {NavButton} from './NavButton'
import {UserProfile} from './UserProfile'
import {useSelector} from "react-redux";
import {getUser, getUserEmail, getUserName, getUserPhoto, userAuthenticated} from "../../views/auth/authReducer";
import {LogoutButton} from "../LogoutButton";
import {MdOutlineAttachMoney} from "react-icons/md";
import {Link} from "react-router-dom";

export const Sidebar = () => {
	const user = useSelector(getUser)
	const userPhoto = useSelector(getUserPhoto)
	const userEmail = useSelector(getUserName)
	const userName = useSelector(getUserEmail)

	const authenticated = useSelector(userAuthenticated)

	return (
		<Flex as="section" minH="100vh" bg="bg-canvas">
			<Flex
				flex="1"
				bg="bg-surface"
				overflowY="auto"
				boxShadow="sm"
				maxW={{
					base: 'full',
					sm: 'xs',
				}}
				py={{
					base: '6',
					sm: '8',
				}}
				px={{
					base: '4',
					sm: '6',
				}}
			>
				<Stack justify="space-between" spacing="1">
					<Stack
						spacing={{
							base: '5',
							sm: '6',
						}}
						shouldWrapChildren
					>
						<Logo/>
						<Stack spacing="1">
							<NavButton to="/explore" label="Explore" icon={FiSearch}></NavButton>
							{/*{authenticated && <NavButton to="/user-onboarding/" label="Getting Started" icon={FiZap}/>}*/}
							{/*{authenticated && <NavButton to="/user-dashboard/inbox/active/all" label="Inbox" icon={FiInbox}/>}*/}
						</Stack>
					</Stack>
					<Stack
						spacing={{
							base: '5',
							sm: '6',
						}}
					>
						<Stack spacing="1">
							{user && <NavButton to="/settings/billing" label="Billing" icon={MdOutlineAttachMoney}></NavButton>}
							{user && <LogoutButton buttonType={'NavButton'}></LogoutButton>}
						</Stack>
						<Divider/>
						{user ? <UserProfile
							name={userName}
							image={userPhoto}
							email={userEmail}
						/> : <Button as={Link} to={"/login"}>Login</Button>}
					</Stack>
				</Stack>
			</Flex>
		</Flex>
	)
}