import {Badge, Box, Text, useColorModeValue,} from "@chakra-ui/react";

export const Card = (props) => {
  const { children, isPopular, ...rest } = props;

  const popularStyles = {
    borderWidth: "2px",
    borderColor: "blue.500",
    zIndex: 1,
    backgroundColor: "rgb(238 242 255)",
  };

  const styles = isPopular ? popularStyles : null;
  return (
    <Box
      bg={useColorModeValue("white", "gray.700")}
      position="relative"
      px="6"
      pb="6"
      pt="16"
      overflow="hidden"
      shadow="lg"
      width="100%"
      height={{ base: "auto", lg: "auto", xl: "625px" }}
      {...styles}
      {...rest}>
      {isPopular && (
        <Badge
          colorScheme="green"
          alignSelf="start"
          position="absolute"
          top="25px"
          right="33px"
          size={'md'}>
          <Text fontSize="xs">Most Popular</Text>
        </Badge>
      )}
      {children}
    </Box>
  );
};
