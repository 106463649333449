import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getCookie} from "./marketingRefSourceInitializer";

const baseQuery = fetchBaseQuery({
	baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api`,
	// prepareHeaders is used to configure the header of every request and gives access to getState which we use to include the token from the store
	prepareHeaders: (headers, { getState }) => {
		const token = getState().authReducer.userToken
		if (token) {
			headers.set('authorization', `Bearer ${token}`)
		}
		const uniqueBrowserId = getCookie("unique_browser_id");
		if (uniqueBrowserId) {
			headers.set('unique-browser-id', uniqueBrowserId)
		}
		return headers
	},
})

export const backendApi = createApi({
	reducerPath: 'backendApi',
	baseQuery: baseQuery,
	endpoints: (builder) => ({
		getUserProfile: builder.query({
			query: () => `/user/`,
		}),
		getCategories: builder.query({
			query: () => `/categories`,
		}),
		getCategory: builder.query({
			query: (categoryId) => `/categories/${categoryId}`,
		}),
		getBooksByCategory: builder.query({
			query: ({categoryId, page}) => `/books/by_category/${categoryId}?page=${page}`,
			providesTags: ['book'],
		}),
		getFreeBook: builder.query({
			query: () => `/books/get_free_book`,
		}),
		getBook: builder.query({
			query: (bookId) => `/books/${bookId}`,
			providesTags: ['book'],
		}),
		getBookSection: builder.query({
			query: ({bookId, sectionId}) => {
				return `/books/${bookId}/sections/${sectionId}`
			},
			invalidatesTags: ['book'],
		}),
		getLibraryBooks: builder.query({
			query: ({filter, page}) => {
				return `/library/books?filter=${filter}&page=${page}`
			},
			providesTags: ['book']
		}),
		getBooks: builder.query({
			query: ({filter, page, search}) => {
				if (search === undefined) { search = ''}
				const params = new URLSearchParams({ filter, page, search });
				return `/books?${params.toString()}`
			},
			providesTags: ['book'],
		}),
		postCheckoutUrl: builder.mutation({
			query: (plan) => ({
				url: `/checkout/${plan}`,
				method: 'POST',
				body: { },
			}),
		}),
		postBillingPortalUrl: builder.mutation({
			query: () => ({
				url: `/billings`,
				method: 'POST',
				body: { },
			}),
		}),
		postBookFinished: builder.mutation({
			query: (book_id) => ({
				url: `/library/mark_book_finished/${book_id}`,
				method: 'POST',
				body: { },
			}),
			invalidatesTags: ['book'],
		}),
		postBookQueued: builder.mutation({
			query: (book_id) => ({
				url: `/library/mark_book_queued/${book_id}`,
				method: 'POST',
				body: { },

			}),
			invalidatesTags: ['book'],
		}),
		postBookUnqueued: builder.mutation({
			query: (book_id) => ({
				url: `/library/mark_book_unqueued/${book_id}`,
				method: 'POST',
				body: { },
			}),
			invalidatesTags: ['book'],
		}),
	}),
})

export const {
	useGetUserProfileQuery,
	useGetCategoriesQuery,
	useGetCategoryQuery,
	useGetBooksByCategoryQuery,
	useGetFreeBookQuery,

	useGetBookQuery,
	useGetBookSectionQuery,
	useGetBooksQuery,
	useGetLibraryBooksQuery,
	usePostCheckoutUrlMutation,
	usePostBillingPortalUrlMutation,
	usePostBookFinishedMutation,
	usePostBookQueuedMutation,
	usePostBookUnqueuedMutation,

} = backendApi
