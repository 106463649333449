import {Box, Button, Center, Divider, Flex, Spinner, Stack, Text} from "@chakra-ui/react";
import {NavLink as ReactRouterLink} from "react-router-dom";
import {BillingSummary} from "./billing-summary";
import {PlanCopy} from "./copy-constants";
import {useGetUserProfileQuery} from "../../../services/backendApi";
import {ManageBillingButton} from "../../../components/StripeButtons";


export const Billing = () => {
	const { data: userData, isSuccess, isLoading: loading } = useGetUserProfileQuery(undefined, {});

	if (loading) {
		return (
			<Center w="100%" h="150px" >
				<Spinner pt={"100px"} />
			</Center>
		);
	}

	const account = userData?.included[0];
	return (
		<Stack spacing="5">
			<Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
				<Box>
					<Text fontSize="lg" fontWeight="medium">
						Your Subscription
					</Text>
					<Text color="muted" fontSize="sm">
						Current plan type and status
					</Text>
				</Box>
				<CurrentPlanStatus
					plan={account.attributes.plan}
					status={account.attributes.status}
				/>
			</Stack>
			<Divider />
			<Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
				<Box>
					<Text fontSize="lg" fontWeight="medium">
						Billing Summary
					</Text>
					<Text color="muted" fontSize="sm">
						Details of your upcoming charges, and renewal schedule
					</Text>
				</Box>
				<Box>
					<BillingSummary
						upcomingInvoiceAmount={account.attributes.upcoming_invoice_amount}
						upcomingAmountOff={account.attributes.upcoming_amount_off}
						upcomingPercentOff={account.attributes.upcoming_percent_0ff}
						upcomingChargeDate={account.attributes.upcoming_charge_date}
						status={account.attributes.status}
						trialEnd={account.attributes.trial_end}
						canceledAt={account.attributes.canceled_at}
						currentPeriodEnd={account.attributes.current_period_end}
						plan={account.attributes.plan}
						cancelAt={account.attributes.cancel_at}
					/>
				</Box>
			</Stack>
			<Divider />
			<Stack spacing="4" direction={{ base: "column", sm: "row" }} justify="space-between">
				<Box>
					{!account.attributes.plan || !account.attributes.status ? (
						<>
							<Text fontSize="lg" fontWeight="medium">
								Start your Skimwise Subscription
							</Text>
							<Text color="muted" fontSize="sm">
								Looks like you haven't started a Skimwise subscription yet!
							</Text>
						</>
					) : (
						<>
							<Text fontSize="lg" fontWeight="medium">
								Manage your Skimwise Subscription
							</Text>
							<Text color="muted" fontSize="sm">
								View billing history, update payment methods, or cancel your subscription
							</Text>
						</>
					)}
				</Box>
				{!account.attributes.plan || !account.attributes.status ? (
					<Flex alignItems="center">
						<Button as={ReactRouterLink} to="/plan-selection" variant="primary" alignSelf="start">
							Subscribe to Premium
						</Button>
					</Flex>
				) : (
					<Flex alignItems="center">
						<ManageBillingButton />
					</Flex>
				)}
			</Stack>
		</Stack>
	);
};

const CurrentPlanStatus = ({ plan, status }) => {
	if (status) {
		return (
			<Flex alignItems="center">
				<Stack spacing={0} alignItems={{ base: "flex-start", sm: "flex-end" }}>
					<Text fontWeight="medium">{PlanCopy[status]}</Text>
					<Text fontSize="sm" color="muted" textAlign={{ base: "left", sm: "right" }}>
						{status === "active"
							? `Pro ${plan && plan.charAt(0).toUpperCase() + plan.slice(1) + "ly"} Plan`
							: "Free Plan"}
					</Text>
				</Stack>
			</Flex>
		);
	} else {
		return (
			<Flex alignItems="flex-end">
				<Stack spacing={0} alignItems={{ base: "flex-start", sm: "flex-end" }}>
					<Text fontWeight="medium">Incomplete</Text>
					<Text fontSize="sm" color="muted" textAlign={{ base: "left", sm: "right" }}>
						Free Plan
					</Text>
				</Stack>
			</Flex>
		);
	}
};
