export const grabFirstName = (fullName) => {
  if (fullName) {
    try {
      return fullName.split(" ")[0];
    } catch (e) {
      return "";
    }
  } else {
    return "";
  }
};
