import {Outlet} from 'react-router-dom';
import {Box} from "@chakra-ui/react";
import {Navbar} from "../../components/navbar";
import {CtaBanner} from "../explore";

function Books() {
	return (
		<>
			<Box as="section">
				<CtaBanner />
				<Navbar />
				<Outlet />
			</Box>
		</>
	);
}

export default Books
