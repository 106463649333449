import {
	Box,
	Button,
	ButtonGroup,
	Container,
	Drawer,
	DrawerContent,
	DrawerOverlay,
	Flex,
	HStack,
	useBreakpointValue,
	useDisclosure,
} from '@chakra-ui/react'
import {Logo} from '../Logo'
import {Sidebar} from './Sidebar'
import {ToggleButton} from './ToggleButton'
import {Link, NavLink as RouterNavLink, useLocation} from "react-router-dom";
import {UserDesktopMenu} from "./UserDesktopMenu";
import {useSelector} from "react-redux";
import {userAuthenticated} from "../../views/auth/authReducer";
import {useEffect, useRef, useState} from "react";
import {SearchBox} from "./SearchBox";

const ChakraNavLink = ({ to, exact, children }) => {
	const location = useLocation();
	const pathIncludesWorks = exact ? false : location.pathname.toString().includes(to)

	return(
		<RouterNavLink to={to} exact={exact}>
			{(props) => {
				const { isActive } = props;
				return (
					<Button aria-current={(isActive || pathIncludesWorks) ? 'page' : ''}>
						{children}
					</Button>
				);
			}}
		</RouterNavLink>
	)
}

export const Navbar = () => {
	const isDesktop = useBreakpointValue({
		base: false,
		lg: true,
	})

	const [isSticky, setIsSticky] = useState(false);

	const handleScroll = () => {
		setIsSticky(window.pageYOffset > navbarRef.current.offsetTop);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const navbarRef = useRef(null);

	const { isOpen, onToggle, onClose } = useDisclosure()
	const authenticated = useSelector(userAuthenticated)
	const showUserButtons = isDesktop && authenticated

	return (
		<Box
			ref={navbarRef}
			as="nav"
			position={isSticky ? 'fixed' : 'static'}
			top="0"
			left="0"
			right="0"
			zIndex="sticky"
			bg="bg-surface" >
			<Container
				maxW="100%"
				py={{
					base: '3',
					lg: '4',
				}}
			>
				<Flex justify="space-between">
					<HStack spacing="4">
						<Link to='/explore'><Logo /></Link>
						{isDesktop ? (
							<>
								<ButtonGroup pl={"20px"} variant="ghost" spacing="1">
									<ChakraNavLink to="/explore">Explore</ChakraNavLink>
								</ButtonGroup>
								{showUserButtons ?
									<ButtonGroup variant="ghost" spacing="1">
										<ChakraNavLink to="/library">My Library</ChakraNavLink>
									</ButtonGroup> : <></>}
							</>
						) : <></>}
						<SearchBox />
					</HStack>
					{isDesktop ? (
						<UserDesktopMenu></UserDesktopMenu>
					) : (
						<>
							<ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
							<Drawer
								isOpen={isOpen}
								placement="left"
								onClose={onClose}
								isFullHeight
								preserveScrollBarGap
								// Only disabled for showcase
								trapFocus={false}
							>
								<DrawerOverlay />
								<DrawerContent>
									<Sidebar />
								</DrawerContent>
							</Drawer>
						</>
					)}
				</Flex>
			</Container>
		</Box>
	)
}