import {Link, Outlet} from 'react-router-dom';
import {Box, Button, Container, Stack, Text} from "@chakra-ui/react";
import {Navbar} from "../../components/navbar";
import {useGetUserProfileQuery} from "../../services/backendApi";
import {useSelector} from "react-redux";
import {getAccount} from "../auth/authReducer";

export const CtaBanner = () => {
	const { data: userData, isSuccess, isLoading: loading } = useGetUserProfileQuery(undefined, {});

	const account = useSelector(getAccount)

	let cta
	let message
	if (userData) {
		if (account?.attributes?.status === 'active') {
			return (
				<>
				</>
			)
		} else {
			message = `Upgrade to Premium to unlock access to 1000+ books for $2.49/month!`
			cta = <Button as={Link} to="/plan-selection" variant="primary">Upgrade now</Button>
		}

	} else {
		message = "Create an account to read our free book summaries"
		cta = <Button as={Link} to="/signup" variant="primary">Sign Up</Button>
	}


	return (
		<Box as="section" pb={"5"}>
			<Box borderBottomWidth="1px" bg="bg.surface">
				<Container py={{ base: '4', md: '3.5' }} maxW="100%">
					<Stack
						spacing="4"
						justify={{ base: 'start', md: 'space-between' }}
						direction={{ base: 'column', md: 'row' }}
						align={"center"}
					>
						<Box pe={{ base: '4', md: '0' }}>
							<Text fontWeight="medium">{message}</Text>
						</Box>
						<Stack
							direction={{ base: 'column', md: 'row' }}
							spacing="3"
							align={{ base: 'stretch', md: 'center' }}
						>
							{cta}
						</Stack>
					</Stack>
				</Container>
			</Box>
		</Box>
	)
}

function ExplorePages() {
	return (
		<>
			<Box as="section">
				<CtaBanner />
				<Navbar />
				<Outlet />
			</Box>
		</>
	);
}

export default ExplorePages
