import {combineReducers} from "redux";
import userSetupReducer from "./userSetupReducer";
import authReducer from "../../views/auth/authReducer";
import {backendApi} from "../../services/backendApi";

const rootReducer = combineReducers({
	userSetupReducer: userSetupReducer,
	authReducer: authReducer,
	[backendApi.reducerPath]: backendApi.reducer,
});

export default rootReducer;