import {Flex, Stack, Text} from "@chakra-ui/react";
import numbro from "numbro";
import {dayJs} from "../../../utils/dayjs";

export const BillingSummary = ({
	                               upcomingInvoiceAmount,
	                               upcomingAmountOff,
	                               upcomingPercentOff,
	                               status,
	                               trialEnd,
	                               canceledAt,
	                               cancelAt,
	                               currentPeriodEnd,
                               }) => {
	return (
		<Flex alignItems="center">
			<Stack
				spacing={0}
				alignItems={{ base: "flex-start", sm: "flex-end" }}
				textAlign={{ base: "left", sm: "right" }}>
				{
					<Text fontWeight="medium">
						Upcoming charge: $
						{numbro((upcomingInvoiceAmount ?? 0) / 100).format({
							thousandSeparated: true,
							mantissa: 2,
						})}
					</Text>
				}
				<CouponAmount
					upcomingAmountOff={upcomingAmountOff}
					upcomingPercentOff={upcomingPercentOff}
				/>
				<PlanSummary
					status={status}
					trialEnd={trialEnd}
					canceledAt={canceledAt}
					cancelAt={cancelAt}
					currentPeriodEnd={currentPeriodEnd}
				/>
			</Stack>
		</Flex>
	);
};

const CouponAmount = ({
	                      upcomingAmountOff,
	                      upcomingPercentOff,
                      }) => {
	if (upcomingAmountOff || upcomingPercentOff) {
		return (
			<Text color="muted" fontSize="sm">
				(Discount applied,{" "}
				{upcomingAmountOff
					? `$${numbro(upcomingAmountOff / 100).format({ thousandSeparated: true, mantissa: 0 })}`
					: `${upcomingPercentOff}%`}{" "}
				off next invoice)
			</Text>
		);
	}

	return null;
};

const PlanSummary = ({
	                     status,
	                     trialEnd,
	                     canceledAt,
	                     cancelAt,
	                     currentPeriodEnd,
                     }) => {
	if (status === "trialing") {
		// const msg = `After your free trial ends`;
		const endMessage = canceledAt
			? `Your plan ends on ${dayJs(trialEnd).format("MMMM D, YYYY")}`
			: `Your plan renews on ${dayJs(trialEnd).format("MMMM D, YYYY")}`;

		return (
			<Text color="muted" fontSize="sm">
				{endMessage}
			</Text>
		);
	} else if (status === "active") {
		const copy = canceledAt
			? `Your plan will end on ${dayJs(cancelAt).format("MMMM D, YYYY")}`
			: `Your plan renews on ${dayJs(currentPeriodEnd).format("MMMM D, YYYY")}`;
		return (
			<Text fontSize="sm" color="muted">
				{copy}
			</Text>
		);
	} else if (status === "canceled") {
		return (
			<Text fontSize="sm" color="muted">{`Your plan was deactivated on ${dayJs(
				canceledAt
			).format("MMMM D, YYYY")}`}</Text>
		);
	} else if (status === "past_due") {
		return (
			<Text fontSize="sm" color="muted" w="sm" textAlign={{ base: "left", sm: "right" }}>
				You have an outstanding balance. Please update your payment method or contact Skimwise Support.
			</Text>
		);
	}

	return (
		<Text fontSize="sm" color="muted" w="sm" textAlign={{ base: "left", sm: "right" }}>
			You don't have a subscription yet.
		</Text>
	);
};
