import Home from "./home";
import Collections from "./collections";
import RecentlyAdded from "./recently-added";
import Categories from "./categories";
import MostPopular from "./most-popular";
import FreeToRead from "./free-to-read";

const exploreRoutesList = [
	{
		path: "",
		element: <Home />,
		title: "Explore"
	},
	{
		path: "collections",
		element: <Collections />,
		title: "Collections"
	},
	{
		path: "categories",
		element: <Categories />,
		title: "Categories"
	},
	{
		path: "recently-added",
		element: <RecentlyAdded />,
		title: "Recently Added"
	},
	{
		path: "most-popular",
		element: <MostPopular />,
		title: "Most Popular"
	},
	{
		path: "free-to-read",
		element: <FreeToRead />,
		title: "Free To Read"
	}


];

export default exploreRoutesList;