import {Box, Icon, Link, Square, Stack, Text, useBreakpointValue, useColorModeValue,} from "@chakra-ui/react";
import {BiHeadphone} from "react-icons/bi";

export const PlanSelectionFooter = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      bg="bg-surface"
      boxShadow={useColorModeValue("sm", "sm-dark")}
      px={{ base: "5", md: "5" }}
      py={{ base: "4", md: "2.5" }}
      position="relative"
      borderRadius="xl">
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        spacing={{ base: "3", md: "2" }}>
        <Stack
          spacing="4"
          direction={{ base: "column", md: "row" }}
          align={{ base: "start", md: "center" }}>
          {!isMobile && (
            <Square size="12" bg="bg-subtle" borderRadius="md">
              <Icon as={BiHeadphone} boxSize="6" />
            </Square>
          )}
          <Text fontWeight="medium">Want to check out our 10 free books first?</Text>
          <Text color="muted">
            <Link
              href="/explore"
              pl={"20px"}
              textDecoration="underline">
              Skip payment for now
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};
